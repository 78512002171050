import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'
import Loader from "../hooks/useLoader"
import {Link} from 'react-router-dom'

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, error, isLoading} = useLogin()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await login(email, password)
        setLoading(false)
    }

    return (
      <div className="loaggIn">
        {loading && (
           Loader()
        )}
        <form className="login" onSubmit={handleSubmit}>
            <h3>Log In</h3>
            <br />
            <label>Email:</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label>Password:</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <br />
            <button disabled={isLoading}>Submit</button>
            <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
            
            {error && <div className="error">{error}</div>}
            

        </form>
      </div>
    )
}

export default Login