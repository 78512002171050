// components
import ContactForm from '../components/ContactForm'
import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'

const Contact = () => { 

      return(
        <div className="emails">

            {/* <h1 className="title">Get in Touch</h1> */}

            <ContactForm />

            <ContactInfo/>

            <Footer/>

        </div>
        )
  }
  
  export default Contact