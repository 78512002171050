import { useStaffContext } from '../hooks/useStaffContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useState } from 'react'
// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import {BACKEND_URI} from "../config";



const StaffDetails = ({ staff, handleSelect }) => {

    const { dispatch } = useStaffContext()
    const { user } = useAuthContext()
    const [checked, setChecked] = useState(false)

    const handleCheck = (isChecked, data) =>{
        setChecked(isChecked)
        handleSelect(isChecked, data)
    }
    const handleClick = async () => {
        if(!user){
            return
        }

        const result = window.confirm("Want to Delete this Person?")

        if (result) {

            const response = await fetch(`${BACKEND_URI}/api/staff/mystaff/${staff._id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'DELETE_STAFF', payload: json})
            }
        }


    }



    return (

        <div className="staff-details">
            <div className="staff-content">
                <div className="checkboxtext">
                    <h4>{staff.fullname} </h4>
                    <input  type="checkbox" value={checked} onChange={()=>handleCheck(!checked, `${staff.fullname}`)} />
                </div>
                <p><strong>ΕΙΔΙΚΟΤΗΤΑ: </strong>{staff.specialty}</p>
                {/* <p><strong>ID Number: </strong>{staff.idnumber}</p>
                <p><strong>Soc. Ins. Number: </strong>{staff.soc_ins}</p> */}
                <p>{formatDistanceToNow(new Date(staff.createdAt),{ addSuffix: true })}</p>
            </div>
            <div className="project-buttons">

                <span className="material-symbols-outlined" onClick={handleClick}>delete</span>

            </div>
        </div>

    )
}


export default StaffDetails
