import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import {BACKEND_URI} from "../config";


export const useSignup = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()
    const [loading, setLoading] = useState(false)

    const signup = async (email, password) => {
        setIsLoading(true)
        setError(null)
        setLoading(true)
        const response = await fetch(`${BACKEND_URI}/api/user/signup`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, password})
        })
        console.log(JSON.stringify(response))
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
            setLoading(false)
        }
        if (response.ok) {
            // save the user to local storage
            localStorage.setItem('user', JSON.stringify(json))

            // update the auth context
            dispatch({type: 'LOGIN', payload: json})
            setLoading(false)
            setIsLoading(false)
        }
    }

    return { signup, isLoading, loading, error }
}
