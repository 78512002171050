import { useEffect, useState } from 'react'
import { useDiariesContext } from '../hooks/useDiariesContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useParams } from 'react-router-dom'

// components
import DiaryDetails from '../components/DiaryDetails'
// import DiaryForm from '../components/DiaryForm'
import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import {BACKEND_URI} from "../config"
import Loader from "../hooks/useLoader"

import jsPDF from 'jspdf'
// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { format } from "date-fns"
// for greek characters
import {fontString} from "../font"



const Book = () => {
   const {diaries, dispatch} = useDiariesContext()
   const {user} = useAuthContext()
   const {projectId} = useParams()
   const [loading, setLoading] = useState(false)

   useEffect(() => {
    const fetchDiaries = async () => {
        setLoading(true)
        const response = await fetch(`${BACKEND_URI}/api/diaries/mydiaries?projectId=${projectId}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (response.ok){
            dispatch({type: 'SET_DIARIES', payload: json})
            setLoading(false)
        }
    }
    if(user){
        fetchDiaries()
        
    }

   }, [dispatch, user, projectId])

   const createBook = (e) => {
       
    e.preventDefault()
    // console.log(diaries)
    const book=new jsPDF('portait', 'px', 'a4', 'false')
    book.addFileToVFS("Arialbd.ttf", fontString)
    book.addFont("Arialbd.ttf", "Arialbd", "normal")
    book.setFont('Arialbd', 'normal')
    

    for (const [key, value] of Object.entries(diaries)) {

        console.log(key, value.project_id)

        // Convert HTML variables from DB to Text
        const convertVisitors = value.visitors.replace(/<[^>]+>/g, '')
        const convertAccidents = value.accidents.replace(/<[^>]+>/g, '')
        const convertDelays = value.delays.replace(/<[^>]+>/g, '')
        const convertMaterial = value.material.replace(/<[^>]+>/g, '')
        const convertRemoval = value.removal.replace(/<[^>]+>/g, '')
        const convertDailyWorks = value.dailyWorks.replace(/<[^>]+>/g, '')
        const convertInstructions = value.instructions.replace(/<[^>]+>/g, '')

        // page number
        let pageNumber = key

        // Header Title Ημερήσιας Αναφοράς & Αριθμός Αναφοράς
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(25, 15, 'ΕΡΓΟ: ')
        book.text(50, 15, value.project_title)
        book.text(300, 15, 'ΑΡΙΘΜΟΣ ΑΝΑΦΟΡΑΣ: ')
        book.text(385, 15, pageNumber)
        

        // border of ΗΜΕΡΟΜΗΝΙΑ-ΗΜΕΡΑ-ΩΡΑΡΙΟ
        book.setLineWidth(1)
        book.rect(203, 20, 227, 10)

        // border & fill color of ΗΜΕΡΟΜΗΝΙΑ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 20, 58, 20)
        book.rect(203.5, 20.5, 57, 9, 'F')

        // text of ΗΜΕΡOMHNIA
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 28, 'ΗΜΕΡΟΜΗΝΙΑ')
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(206, 37.5, format(new Date(value.selectdate), "dd-MM-yyyy"))
        
        
        // border & fill color of ΗΜΕΡΑ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(261, 20, 43, 20)
        book.rect(261.5, 20.5, 42, 9, 'F')

        // translate name of selected day to greek
        const dayName = format(new Date(value.selectdate), "EEEE")
        let dayNameGreek = ""
        
        if(dayName==="Monday") {
            dayNameGreek = 'Δευτέρα'
        }
        if(dayName==="Tuesday") {
            dayNameGreek = 'Τρίτη'
        }
        if(dayName==="Wednesday") {
            dayNameGreek = 'Τετάρτη'
        }
        if(dayName==="Thursday") {
            dayNameGreek = 'Πέμπτη'
        }
        if(dayName==="Friday") {
            dayNameGreek = 'Παρασκευή'
        }
        if(dayName==="Saturday") {
            dayNameGreek = 'Σάββατο'
        }
        if(dayName==="Sunday") {
            dayNameGreek = 'Κυριακή'
        }
            
        // text of ΗΜΕΡA
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(264, 28, 'ΗΜΕΡA')
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(264, 37, dayNameGreek )


        // border & fill color of ΩΡΑΡΙΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(304, 20, 126, 20)
        book.rect(304, 20, 126, 10)
        book.rect(304.5, 20.5, 125, 9, 'F')

        // text of ΩΡΑΡΙΟ ΕΡΓΑΣΙΑΣ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(307, 28, 'ΩΡΑΡΙΟ ΕΡΓΑΣΙΑΣ')
        book.setFontSize(9.5)
        book.rect(392, 20, 38, 20)
        book.text(394.5, 28, 'ΔΙΑΚΟΠΗ')
        book.setTextColor(40, 66, 131)
        //ΑΠΟ
        book.rect(304, 30, 22, 10)
        book.setFillColor(115, 198, 182)
        book.rect(304.5, 30.5, 21, 9, 'F')
        book.setTextColor(0, 0, 0)
        book.text(307, 37.5,'ΑΠΟ:')
        book.setTextColor(40, 66, 131)
        book.text(328, 37.5, value.startTime)
        //ΕΩΣ
        book.rect(348, 30, 21, 10)
        book.setFillColor(115, 198, 182)
        book.rect(348.5, 30.5, 20, 9, 'F')
        book.setTextColor(0, 0, 0)
        book.text(351, 37.5, 'ΕΩΣ:')
        book.setTextColor(40, 66, 131)
        book.text(371, 37.5, value.finishTime)
        //ΔΙΑΚΟΠΗ
        book.text(405,37.5, value.breakTime)



        // border of ΚΑΙΡΟΣ-ΑΝΕΜΟΙ-ΘΕΡΜΟΚΡΑΣΙΑ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 40, 227, 20)

        // border of ΚΑΙΡΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 40, 58, 10)
        book.rect(203.5, 40.5, 57.5, 9, 'F')
        book.rect(203, 40, 58, 20)

        // text of ΚΑΙΡΙΚΕΣ ΣΥΝΘΗΚΕΣ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 48,'ΚΑΙΡΟΣ')
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(206, 57.5, value.weather)


        // border of ΑΝΕΜΟΙ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(261, 40, 43, 10)
        book.rect(261.5, 40.5, 42.5, 9, 'F')
        book.rect(261, 40, 43, 20)

        // text of ΑΝΕΜΟΙ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(264, 48,'ANEMOI')
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(264, 57.5, value.wind)


        // border of ΘΕΡΜΟΚΡΑΣΙΑ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(304, 40, 126, 10)
        book.rect(304.5, 40.5, 125, 9, 'F')

        // border of ΧΑΜΗΛΗ
        book.rect(304, 50, 37, 10)
        book.rect(304.5, 50.5, 36, 9, 'F')
        // border of ΥΨΗΛΗ
        book.rect(369, 50, 32, 10)
        book.rect(369.5, 50.5, 31, 9, 'F')


        
        // text of ΘΕΡΜΟΚΡΑΣΙΑ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(307,48,'ΘΕΡΜΟΚΡΑΣΙΑ')

        // text of ΧΑΜΗΛΗ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.setFontSize(9)
        book.text(307, 57.5,'ΧΑΜΗΛΗ:')
        book.setTextColor(40, 66, 131)
        book.text(346, 57.5, value.tempMin)

        // text of ΥΨΗΛΗ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.setFontSize(9)
        book.text(372, 57.5,'ΥΨΗΛΗ:')
        book.setTextColor(40, 66, 131)
        book.text(405, 57.5, value.tempMax)


        // border of ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 60, 227, 40)
        book.rect(203, 60, 227, 10)
        book.rect(203.5, 60.5, 226, 9, 'F')

        //text of ΠΑΡΑΛΑBΗ ΥΛΙΚΩΝ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 68, 'ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ')

        // split text ΠΑΡΑΛΑBΗ ΥΛΙΚΩΝ
        const textlinesMaterial = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertMaterial, 222)
        book.text(206, 76, textlinesMaterial)


        // border of ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 100, 227, 40)
        book.rect(203, 100, 227, 10)
        book.rect(203.5, 100.5, 226, 9, 'F')

        //text of ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 108, 'ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ')

        // split text ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        const textlinesRemoval = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertRemoval, 222)
        book.text(206, 116, textlinesRemoval)



        // border of ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 140, 227, 40)
        book.rect(203, 140, 227, 10)
        book.rect(203.5, 140.5, 226, 9, 'F')


        //text of ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 148,'ΚΑΘΥΣΤΕΡΗΣΕΙΣ')

        // split text ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        const textlinesDelays = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertDelays, 222)
        book.text(206, 156, textlinesDelays)



        // border of ΑΤΥΧΗΜΑΤΑ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 180, 227, 40)
        book.rect(203, 180, 227, 10)
        book.rect(203.5, 180.5, 226, 9, 'F')

        //text of ΑΤΥΧΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 188, 'ΑΤΥΧΗΜΑΤΑ')

        // split text ΑΤΥΧΗΜΑΤΑ
        const textlinesAccidents = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertAccidents, 222)
        book.text(206, 196, textlinesAccidents)

        

        // border of ΕΠΙΣΚΕΠΤΕΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 220, 227, 40)
        book.rect(203, 220, 227, 10)
        book.rect(203.5, 220.5, 226, 9, 'F')

        //text of ΕΠΙΣΚΕΠΤΕΣ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 228, 'ΕΠΙΣΚΕΠΤΕΣ')

        // // split text ΕΠΙΣΚΕΠΤΕΣ here we split the text base of text-length and we reduce the font-size of text
        // if(convertVisitors.length > 175){
        const textlinesVisitors = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertVisitors, 222)
        book.text(206, 236, textlinesVisitors)
        // }else{
        //     const textlinesVisitors = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertVisitors, 130)
        //     book.text(23, 552, textlinesVisitors)
        // }



        // border of ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(203, 260, 227, 10)
        book.rect(203, 260, 227, 80)
        book.rect(203.5, 260.5, 226, 9, 'F')


        //text of ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(206, 268, 'ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ')
        book.setFontSize(8)
        //book.text(310, 428, 'ΥΠΟΓΡΑΦΗ ΕΠΙΒΛΕΠΟΝΤΑ ΜΗΧΑΝΙΚΟΥ')
        //book.addImage(diary.signature,'PNG', 310, 430, 115, 35)

        // split text ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        const textlinesInstructions = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertInstructions, 222)
        book.text(206, 276, textlinesInstructions)




        // border of ΠΡΟΣΩΠΙΚΟ-ΕΡΓΟΛΑΒΟΥ-ΥΠΕΡΓΟΛΑΒΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 20, 180, 20)

        // border & fill color of ΠΡΟΣΩΠΙΚΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 20, 65, 10)
        book.rect(20.5, 20.5, 64, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(24, 28, 'ΠΡΟΣΩΠΙΚΟ')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 20, 52, 10)
        book.rect(85.5, 20.5, 51, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(88, 28, 'ΕΡΓΟΛΑΒΟΥ')

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 20, 63, 10)
        book.rect(137.5, 20.5, 62, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(140, 28, 'ΥΠΕΡΓΟΛΑΒΩΝ')


        // border & fill color of ΠΡΟΣΩΠΙΚΟ - ΚΑΛΟΥΨΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 30, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 37.5, 'Καλουψιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΑΛΟΥΨΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 30, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 38, value.kaMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΑΛΟΥΨΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 30, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 38, value.kaSub)


        // border & fill color of ΠΡΟΣΩΠΙΚΟ - ΣΙΔΕΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 40, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 47.5, 'Σιδεράς')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΣΙΔΕΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 40, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 48, value.siMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΣΙΔΕΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 40, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 48, value.siSub)


        // border & fill color of ΚΤΙΣΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 50, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 57.5, 'Κτίστης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΤΙΣΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 50, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 58, value.ktMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΤΙΣΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 50, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 58, value.ktSub)


        // border & fill color of EΡΓΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 60, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 67.5, 'Εργάτης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΡΓΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 60, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 68, value.erMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΡΓΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 60, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 68, value.erSub)


        // border & fill color of ΥΔΡΑΥΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 70, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 77.5, 'Υδραυλικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΥΔΡΑΥΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 70, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 78, value.ydMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΥΔΡΑΥΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 70, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 78, value.ydSub)


        // border & fill color of ΗΛΕΚΤΡΟΛΟΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 80, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 87.5, 'Ηλεκτρολόγος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΗΛΕΚΤΡΟΛΟΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 80, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 88, value.ilMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΗΛΕΚΤΡΟΛΟΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 80, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 88, value.ilSub)



        // border & fill color of ΨΥΚΤΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 90, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 97.5, 'Ψυκτικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΨΥΚΤΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 90, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 98, value.psMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΨΥΚΤΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 90, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 98, value.psSub)



        // border & fill color of ΓΥΨΟΣΑΝΙΔΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 100, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 107.5, 'Γυψοσανιδάς')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΓΥΨΟΣΑΝΙΔΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 100, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 108, value.gyMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΓΥΨΟΣΑΝΙΔΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 100, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 108, value.gySub)



        // border & fill color of ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 110, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 117.5, 'Αλουμινιτζιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 110, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 118, value.alMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 110, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 118, value.alSub)


        // border & fill color of ΠΕΛΕΚΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 120, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 127.5, 'Πελεκάνος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΠΕΛΕΚΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 120, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 128, value.peMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΠΕΛΕΚΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 120, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 128, value.peSub)



        // border & fill color of ΜΕΤΑΛΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 130, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 137.5, 'Μεταλλικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΕΤΑΛΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 130, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 138, value.metMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΕΤΑΛΛΙΚΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 130, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 138, value.metSub)


        // border & fill color of ΠΟΓΙΑΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 140, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 147.5, 'Πογιατζιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΠΟΓΙΑΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 140, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 148, value.poMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΠΟΓΙΑΤΖΙΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 140, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 148, value.poSub)



        // border & fill color of ΜΟΝΩΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 150, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 157.5, 'Μονωτής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΟΝΩΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 150, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 158, value.moMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΟΝΩΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 150, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 158, value.moSub)



        // border & fill color of ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 160, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 167.5, 'Μηχ. Εργοταξίου')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 160, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 168, value.meMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 160, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 168, value.meSub)



        // border & fill color of ΕΠΙΣΤΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 170, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 177.5, 'Επιστάτης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΠΙΣΤΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 170, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 178, value.epMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΠΙΣΤΑΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 170, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 178, value.epSub)



        // border & fill color of ΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 180, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 187.5, 'Oδηγός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 180, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 188, value.odMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 180, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 188, value.odSub)



        // border & fill color of ΜΗΧΑΝΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 190, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 197.5, 'Μηχανοδηγός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΗΧΑΝΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 190, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 198, value.miMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΗΧΑΝΟΔΗΓΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 190, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 198, value.miSub)



        // border of ΜΗΧΑΝΗΜΑΤΑ-ΕΡΓΟΛΑΒΟΥ-ΥΠΕΡΓΟΛΑΒΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 200, 180, 20)

        // border & fill color of ΠΡΟΣΩΠΙΚΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 200, 65, 10)
        book.rect(20.5, 200.5, 64, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(24, 208, 'ΜΗΧΑΝΗΜΑΤΑ')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 200, 52, 10)
        book.rect(85.5, 200.5, 51, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(88, 208, 'ΕΡΓΟΛΑΒΟΥ')

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 200, 63, 10)
        book.rect(137.5, 200.5, 62, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(140, 208, 'ΥΠΕΡΓΟΛΑΒΩΝ')



        // border & fill color of ΕΚΣΚΑΦΕΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 210, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 217.5, 'Εκσκαφέας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΚΣΚΑΦΕΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 210, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 218, value.ekMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΚΣΚΑΦΕΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 210, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 218, value.ekSub)



        // border & fill color of ΦΟΡΤΗΓΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 220, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 227.5, 'Φορτηγό')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΦΟΡΤΗΓΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 220, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 228, value.foMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΦΟΡΤΗΓΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 220, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 228, value.foSub)



        // border & fill color of ΟΔΟΣΤΡΩΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 230, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 237.5, 'Οδοστρωτήρας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΟΔΟΣΤΡΩΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 230, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 238, value.odosMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΟΔΟΣΤΡΩΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 230, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 238, value.odosSub)



        // border & fill color of ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 240, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 247.5, 'Αντλία Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 240, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 248, value.anMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 240, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 248, value.anSub)



        // border & fill color of ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 250, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 257.5, 'Βαρέλα Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 250, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 258, value.vaMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 250, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 258, value.vaSub)



        // border & fill color of ΔΟΝΗΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 260, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 267.5, 'Δονητής Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΔΟΝΗΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 260, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 268, value.doMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΔΟΝΗΤΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 260, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 268, value.doSub)




        // border & fill color of ΑΝΑΜΙΚΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 270, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 277.5, 'Αναμικτήρας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΝΑΜΙΚΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 270, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 278, value.amMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΝΑΜΙΚΤΗΡΑΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 270, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 278, value.amSub)



        // border & fill color of FORK LIFT
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 280, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 287.5, 'Παλετοφόρο')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - FORK LIFT
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 280, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 288, value.flMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - FORK LIFT
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 280, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 288, value.flSub)



        // border & fill color of ΤΗΛΕΣΚΟΠΙΚΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 290, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 297.5, 'Τηλεσκοπικό')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΤΗΛΕΣΚΟΠΙΚΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 290, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 298, value.tiMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΤΗΛΕΣΚΟΠΙΚΟ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 290, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 298, value.tiSub)



        // border & fill color of ΒΙΝΤΖΙ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 300, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 307.5, 'Βίντζι')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΒΙΝΤΖΙ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 300, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 308, value.viMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΒΙΝΤΖΙ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 300, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 308, value.viSub)



        // border & fill color of ΓΕΡΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 310, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 317.5, 'Γερανός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΓΕΡΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 310, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 318, value.geMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΓΕΡΑΝΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 310, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 318, value.geSub)



        // border & fill color of ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 320, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 327.5, 'Συμπιεστής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 320, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 328, value.syMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 320, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 328, value.sySub)




        // border & fill color of ΚΟΜΠΡΕΣΣΟΡΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 330, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(9)
        book.setTextColor(40, 66, 131)
        book.text(24, 337.5, 'Κομπρεσσόρος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΟΜΠΡΕΣΣΟΡΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(85, 330, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(107, 338, value.ksMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΟΜΠΡΕΣΣΟΡΟΣ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(137, 330, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        book.setFontSize(10)
        book.setTextColor(40, 66, 131)
        book.text(165, 338, value.ksSub)



        // border of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.setFillColor(115, 198, 182)
        book.rect(20, 355, 410, 10)
        book.rect(20, 355, 410, 225)
        book.rect(20.5, 355.5, 409, 9, 'F')


        //text of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(25, 363, 'ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ')


        // split text ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        const textlinesDailyWorks = book.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertDailyWorks, 400)
        book.text(25, 373, textlinesDailyWorks)




        // border of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        book.setLineWidth(1)
        book.setDrawColor(0, 0, 0)
        book.rect(20, 583, 410, 35)

        //text of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        book.setFontSize(10)
        book.setTextColor(0, 0, 0)
        book.text(25, 593, 'ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ:')

        book.addImage(value.signature,'PNG', 140, 585, 150, 30)

        //save the file
        //doc.save(`${value.selectdate}.pdf`)
        //doc.output('dataurlnewwindow')

        // // border of ΕΡΓΟ-ΗΜΕΡΑ-ΗΜΕΡΟΜΗΝΙΑ-ΑΡΙΘΜΟΣ ΣΕΛΙΔΑΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 20, 410, 20)

        // // text of ΕΡΓΟ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,28,'ΕΡΓΟ:')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(25,37, value.project_title)

        // // border & fill color of ΗΜΕΡΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(225, 20, 60, 20)
        // book.rect(225.5, 20.5, 59, 9, 'F')

        // // translate name of selected day to greek
        // const dayName = format(new Date(value.selectdate), "EEEE")
        // let dayNameGreek = ""

        // if(dayName==="Monday"){
        //     dayNameGreek = 'Δευτέρα'
        // }
        // if(dayName==="Tuesday"){
        //     dayNameGreek = 'Τρίτη'
        // }
        // if(dayName==="Wednesday"){
        //     dayNameGreek = 'Τετάρτη'
        // }
        // if(dayName==="Thursday"){
        //     dayNameGreek = 'Πέμπτη'
        // }
        // if(dayName==="Friday"){
        //     dayNameGreek = 'Παρασκευή'
        // }
        // if(dayName==="Saturday"){
        //     dayNameGreek = 'Σάββατο'
        // }
        // if(dayName==="Sunday"){
        //     dayNameGreek = 'Κυριακή'
        // }
        
        // // page number
        // let pageNumber = key


        // // text of ΗΜΕΡΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(230,28,'ΗΜΕΡΑ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(230,37, dayNameGreek)


        // // border & fill color of ΗΜΕΡΟΜΗΝΙΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(285, 20, 65, 20)
        // book.rect(285.5, 20.5, 64, 9, 'F')

        // // text of ΗΜΕΡΟΜΗΝΙΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(290,28,'ΗΜΕΡΟΜΗΝΙΑ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(290,37, format(new Date(value.selectdate), "dd-MM-yyyy"))



        // // border & fill color of ΑΡΙΘΜΟΣ ΣΕΛΙΔΑΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(350, 20, 80, 20)
        // book.rect(350.5, 20.5, 79, 9, 'F')

        // // text of ΑΡΙΘΜΟΣ ΣΕΛΙΔΑΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(355,28,'ΑΡΙΘΜΟΣ ΣΕΛΙΔΑΣ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(385, 37, pageNumber)
        // book.rect(225, 20, 205, 10)


        // // border of ΚΑΙΡΟΣ-ΑΝΕΜΟΙ-ΘΕΡΜΟΚΡΑΣΙΑ-ΩΡΕΣ ΕΡΓΑΣΙΑΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 40, 410, 30)

        // // border of ΚΑΙΡΟΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 40, 102.5, 10)
        // book.rect(20.5, 40.5, 101.5, 9, 'F')
        // book.rect(20, 40, 102.5, 30)

        // // text of ΚΑΙΡΟΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,48,'ΚΑΙΡΟΣ')
        // book.setFontSize(10)
        // book.setTextColor(40, 66, 131)
        // book.text(25,60, value.weather)



        // // border of ΑΝΕΜΟΙ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(122.5, 40, 102.5, 10)
        // book.rect(123, 40.5, 101.5, 9, 'F')
        // book.rect(122.5, 40, 102.5, 30)

        // // text of ΑΝΕΜΟΙ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(127.5,48,'ANEMOI')
        // book.setFontSize(10)
        // book.setTextColor(40, 66, 131)
        // book.text(127.5,60, value.wind)

        // // border of ΘΕΡΜΟΚΡΑΣΙΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(225, 40, 80, 10)
        // book.rect(225.5, 40.5, 79, 9, 'F')

        // // border of ΕΛΑΧΙΣΤΗ-ΜΕΓΙΣΤΗ
        // book.rect(225, 50, 80, 10)
        // book.rect(225.5, 50.5, 79, 9, 'F')
        // book.rect(225, 50, 43, 20)
        // book.rect(268, 50, 37, 20)

        // // text of ΘΕΡΜΟΚΡΑΣΙΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(230,48,'ΘΕΡΜΟΚΡΑΣΙΑ')

        // // text of ΕΛΑΧΙΣΤΗ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(228,58,'ΕΛΑΧΙΣΤΗ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(238,67, value.tempMin)


        // // text of ΜΕΓΙΣΤΗ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(270,58,'ΜΕΓΙΣΤΗ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(280,67, value.tempMax)


        // // border of ΩΡΕΣ ΕΡΓΑΣΙΑΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(305, 40, 125, 10)
        // book.rect(305.5, 40.5, 124, 9, 'F')

        // // border of ΑΠΟ-ΕΩΣ-ΔΙΑΚΟΠΗ
        // book.rect(305, 50, 125, 10)
        // book.rect(305.5, 50.5, 124, 9, 'F')

        // // border of ΔΙΑΚΟΠΗ
        // book.rect(390, 50, 40, 20)

        // //text of ΩΡΕΣ ΕΡΓΑΣΙΑΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(310,48,'ΩΡΕΣ ΕΡΓΑΣΙΑΣ')

        // // text of ΑΠΟ-ΕΩΣ-ΔΙΑΚΟΠΗ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(315,58,'ΑΠΟ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(315,67, value.startTime)

        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(360,58,'ΕΩΣ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(360,67, value.finishTime)

        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(393,58,'ΔΙΑΚΟΠΗ')
        // book.setFontSize(9)
        // book.setTextColor(40, 66, 131)
        // book.text(405,67, value.breakTime)



        // // border of ΠΡΟΣΩΠΙΚΟ-ΜΗΧΑΝΗΜΑΤΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(128,128,128)
        // book.rect(20, 75, 410, 70)
        // // border of ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ-ΥΠΕΡΓΟΛΑΒΟΙ
        // book.rect(20, 75, 410, 10)
        // book.rect(20, 75, 205, 10)
        // book.rect(20.5, 75.5, 204, 9, 'F')
        // book.rect(225.5, 75.5, 204, 9, 'F')
        // // border of ΠΡΟΣΩΠΙΚΟ
        // book.setFillColor(211,211,211)
        // book.rect(20, 85, 410, 10)
        // book.rect(20, 85, 205, 10)
        // book.rect(20.5, 85.5, 204, 9, 'F')
        // book.rect(225.5, 85.5, 204, 9, 'F')
        // // border of ΕΙΔΙΚΟΤΗΤΕΣ
        // book.rect(20, 95, 410, 20)
        // book.rect(20, 95, 205, 20)
        // book.rect(20, 95, 410, 10)
        // book.setLineWidth(0.5)
        // book.setDrawColor(0, 0, 0)
        // //ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ
        // //ΜΕ
        // book.setTextColor(0, 0, 0)
        // book.rect(20, 95, 13.65, 20)
        // book.text(21.5, 102.5,'ME')
        // book.setTextColor(40, 66, 131)
        // book.text(25, 112.5, value.meMC)
        // //ΕΠ
        // book.setTextColor(0, 0, 0)
        // book.rect(33.65, 95, 13.65, 20)
        // book.text(35.2, 102.5,'ΕΠ')
        // book.setTextColor(40, 66, 131)
        // book.text(39, 112.5, value.epMC)
        // //ΓΡ
        // book.setTextColor(0, 0, 0)
        // book.rect(47.30, 95, 13.65, 20)
        // book.text(49.7, 102.5,'ΓΡ')
        // book.setTextColor(40, 66, 131)
        // book.text(52.5, 112.5, value.grMC)
        // //ΟΔ
        // book.setTextColor(0, 0, 0)
        // book.rect(60.95, 95, 13.65, 20)
        // book.text(62.3, 102.5,'ΟΔ')
        // book.setTextColor(40, 66, 131)
        // book.text(65.5, 112.5, value.odMC)
        // //ΚΤ
        // book.setTextColor(0, 0, 0)
        // book.rect(74.6, 95, 13.65, 20)
        // book.text(77,102.5,'ΚΤ')
        // book.setTextColor(40, 66, 131)
        // book.text(79.5, 112.5, value.ktMC)
        // //ΕΡ
        // book.setTextColor(0, 0, 0)
        // book.rect(88.2, 95, 13.65, 20)
        // book.text(90.5, 102.5,'ΕΡ')
        // book.setTextColor(40, 66, 131)
        // book.text(93, 112.5, value.erMC)
        // //ΚΑ
        // book.setTextColor(0, 0, 0)
        // book.rect(101.9, 95, 13.65, 20)
        // book.text(103.3, 102.5,'ΚΑ')
        // book.setTextColor(40, 66, 131)
        // book.text(106.5, 112.5, value.kaMC)
        // //ΣΙ
        // book.setTextColor(0, 0, 0)
        // book.rect(115.55, 95, 13.65, 20)
        // book.text(118.7,102.5,'ΣΙ')
        // book.setTextColor(40, 66, 131)
        // book.text(121, 112.5, value.siMC)
        // //ΠΕ
        // book.setTextColor(0, 0, 0)
        // book.rect(129.2, 95, 13.65, 20)
        // book.text(130.7, 102.5,'ΠΕ')
        // book.setTextColor(40, 66, 131)
        // book.text(134, 112.5, value.peMC)
        // //ΥΔ
        // book.setTextColor(0, 0, 0)
        // book.rect(142.85, 95, 13.65, 20)
        // book.text(144.35, 102.5,'ΥΔ')
        // book.setTextColor(40, 66, 131)
        // book.text(147.5, 112.5, value.ydMC)
        // //ΗΛ
        // book.setTextColor(0, 0, 0)
        // book.rect(156.5, 95, 13.65, 20)
        // book.text(158, 102.5,'ΗΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(161.15, 112.5, value.ilMC)
        // //ΠΟ
        // book.setTextColor(0, 0, 0)
        // book.rect(170.15, 95, 13.65, 20)
        // book.text(171.65, 102.5,'ΠΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(174.8, 112.5, value.poMC)
        // //ΑΛ
        // book.setTextColor(0, 0, 0)
        // book.rect(183.8, 95, 13.65, 20)
        // book.text(185.5, 102.5,'ΑΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(188.6, 112.5, value.alMC)
        // //Extra 1
        // book.rect(197.45, 95, 13.90, 20)



        // //ΥΠΕΡΟΛΑΒΟΙ
        // //ΚΤ
        // book.setTextColor(0, 0, 0)
        // book.rect(225, 95, 13.65, 20)
        // book.text(226.7,102.5,'ΚΤ')
        // book.setTextColor(40, 66, 131)
        // book.text(229.7, 112.5, value.ktSub)
        // //ΕΡ
        // book.setTextColor(0, 0, 0)
        // book.rect(238.65, 95, 13.65, 20)
        // book.text(240.18, 102.5,'ΕΡ')
        // book.setTextColor(40, 66, 131)
        // book.text(243.18, 112.5, value.erSub)
        // //ΚΑ
        // book.setTextColor(0, 0, 0)
        // book.rect(252.3, 95, 13.65, 20)
        // book.text(253.8, 102.5,'ΚΑ')
        // book.setTextColor(40, 66, 131)
        // book.text(256.8, 112.5, value.kaSub)
        // //ΣΙ
        // book.setTextColor(0, 0, 0)
        // book.rect(265.95, 95, 13.65, 20)
        // book.text(269.5,102.5,'ΣΙ')
        // book.setTextColor(40, 66, 131)
        // book.text(270, 112.5, value.siSub)
        // //ΠΕ
        // book.setTextColor(0, 0, 0)
        // book.rect(279.6, 95, 13.65, 20)
        // book.text(281, 102.5,'ΠΕ')
        // book.setTextColor(40, 66, 131)
        // book.text(284, 112.5, value.peSub)
        // //ΥΔ
        // book.setTextColor(0, 0, 0)
        // book.rect(293.25, 95, 13.65, 20)
        // book.text(294.75, 102.5,'ΥΔ')
        // book.setTextColor(40, 66, 131)
        // book.text(297.75, 112.5, value.ydSub)
        // //ΗΛ
        // book.setTextColor(0, 0, 0)
        // book.rect(306.9, 95, 13.65, 20)
        // book.text(308.5, 102.5,'ΗΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(311.5, 112.5, value.ilSub)
        // //ΠΟ
        // book.setTextColor(0, 0, 0)
        // book.rect(320.55, 95, 13.65, 20)
        // book.text(321.9, 102.5,'ΠΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(324.9, 112.5, value.poSub)
        // //ΑΛ
        // book.setTextColor(0, 0, 0)
        // book.rect(334.2, 95, 13.65, 20)
        // book.text(335.7, 102.5,'ΑΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(338.7, 112.5, value.alSub)
        // //Extra 1
        // book.rect(347.85, 95, 13.65, 20)
        // //Extra 2
        // book.rect(361.5, 95, 13.65, 20)
        // //Extra 3
        // book.rect(375.15, 95, 13.65, 20)
        // //Extra 4
        // book.rect(388.8, 95, 13.65, 20)
        // //Extra 5
        // book.rect(402.45, 95, 13.65, 20)
        // //Extra 6
        // book.rect(416.1, 95, 13.65, 20)



        // // border of ΜΗΧΑΝΗΜΑΤΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 115, 410, 10)
        // book.rect(20, 115, 205, 10)
        // book.rect(20.5, 115.5, 204, 9, 'F')
        // book.rect(225.5, 115.5, 204, 9, 'F')

        // // border of ΕΙΔΗ ΜΗΧΑΝΗΜΑΤΩΝ
        // book.rect(20, 125, 410, 20)
        // book.rect(20, 125, 205, 20)
        // book.rect(20, 125, 410, 10)
        // book.setLineWidth(0.5)
        // book.setDrawColor(0, 0, 0)
        // //ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ
        // //ΕΚ
        // book.rect(20, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(21.5,132.5,'ΕΚ')
        // book.setTextColor(40, 66, 131)
        // book.text(25, 142.5, value.ekMC)
        // //ΚΣ
        // book.rect(33.65, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(35.15,132.5,'ΚΣ')
        // book.setTextColor(40, 66, 131)
        // book.text(38.65, 142.5, value.ksMC)
        // //ΔΟ
        // book.rect(47.30, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(48.8,132.5,'ΔΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(52.3, 142.5, value.doMC)
        // //ΑΜ
        // book.rect(60.95, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(61.8,132.5,'ΑΜ')
        // book.setTextColor(40, 66, 131)
        // book.text(65.3, 142.5, value.amMC)
        // //ΓΕ
        // book.rect(74.6, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(76.3,132.5,'ΓΕ')
        // book.setTextColor(40, 66, 131)
        // book.text(79, 142.5, value.geMC)
        // //ΒΙ
        // book.rect(88.2, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(91,132.5,'ΒΙ')
        // book.setTextColor(40, 66, 131)
        // book.text(92.5, 142.5, value.viMC)
        // //ΦΟ
        // book.rect(101.9, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(102.8,132.5,'ΦΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(106, 142.5, value.foMC)
        // //ΟΔ
        // book.rect(115.55, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(116.9,132.5,'ΟΔ')
        // book.setTextColor(40, 66, 131)
        // book.text(120, 142.5, value.odosMC)
        // //ΣΥ
        // book.rect(129.2, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(131,132.5,'ΣΥ')
        // book.setTextColor(40, 66, 131)
        // book.text(134, 142.5, value.syMC)
        // //ΤΗ
        // book.rect(142.85, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(144.35,132.5,'ΤΗ')
        // book.setTextColor(40, 66, 131)
        // book.text(147, 142.5, value.tiMC)
        // //ΦΛ
        // book.rect(156.5, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(157.8,132.5,'ΦΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(160, 142.5, value.flMC)
        // //Extra 1
        // book.rect(170.15, 125, 13.65, 20)

        // //Extra 2
        // book.rect(183.8, 125, 13.65, 20)
        // //Extra 3
        // book.rect(197.45, 125, 13.90, 20)


        // //ΥΠΕΡΟΛΑΒΟΙ
        // //ΕΚ
        // book.rect(225, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(226.7,132.5,'ΕΚ')
        // book.setTextColor(40, 66, 131)
        // book.text(230.2, 142.5, value.ekSub)
        // //ΚΣ
        // book.rect(238.65, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(240.18, 132.5,'ΚΣ')
        // book.setTextColor(40, 66, 131)
        // book.text(243.68, 142.5, value.ksSub)
        // //ΔΟ
        // book.rect(252.3, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(253.5, 132.5,'ΔΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(257, 142.5, value.doSub)
        // //ΑΜ
        // book.rect(265.95, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(267, 132.5,'ΑΜ')
        // book.setTextColor(40, 66, 131)
        // book.text(270.5, 142.5, value.amSub)
        // //ΓΕ
        // book.rect(279.6, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(281.2, 132.5,'ΓΕ')
        // book.setTextColor(40, 66, 131)
        // book.text(284.7, 142.5, value.geSub)
        // //ΒΙ
        // book.rect(293.25, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(295.9, 132.5,'ΒΙ')
        // book.setTextColor(40, 66, 131)
        // book.text(299.4, 142.5, value.viSub)
        // //ΦΟ
        // book.rect(306.9, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(307.8, 132.5,'ΦΟ')
        // book.setTextColor(40, 66, 131)
        // book.text(311.3, 142.5, value.foSub)
        // //ΟΔ
        // book.rect(320.55, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(321.8, 132.5,'ΟΔ')
        // book.setTextColor(40, 66, 131)
        // book.text(325.3, 142.5, value.odosSub)
        // //ΣΥ
        // book.rect(334.2, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(336.3, 132.5,'ΣΥ')
        // book.setTextColor(40, 66, 131)
        // book.text(339.8, 142.5, value.sySub)
        // //ΤΗ
        // book.rect(347.85, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(349.35, 132.5,'ΤΗ')
        // book.setTextColor(40, 66, 131)
        // book.text(352.85, 142.5, value.tiSub)
        // //ΦΛ
        // book.rect(361.5, 125, 13.65, 20)
        // book.setTextColor(0, 0, 0)
        // book.text(363, 132.5,'ΦΛ')
        // book.setTextColor(40, 66, 131)
        // book.text(366.5, 142.5, value.flSub)
        // //Extra 3
        // book.rect(375.15, 125, 13.65, 20)
        // //Extra 4
        // book.rect(388.8, 125, 13.65, 20)
        // //Extra 5
        // book.rect(402.45, 125, 13.65, 20)
        // //Extra 6
        // book.rect(416.1, 125, 13.65, 20)


        // //text of ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(80,83,'ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ')


        // //text of ΥΠΕΡΓΟΛΑΒΟΙ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(300,83,'ΥΠΕΡΓΟΛΑΒΟΙ')


        // //text of ΠΡΟΣΩΠΙΚΟ (ΚΥΡΙΟΣ ΕΡΓΟΛΟΒΟΥ)
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(95,93,'ΠΡΟΣΩΠΙΚΟ')

        // //text of ΠΡΟΣΩΠΙΚΟ (ΥΠΕΡΓΟΛΑΒΩΝ)
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(305,93,'ΠΡΟΣΩΠΙΚΟ')



        // //text of ΝΗΧΑΝΗΜΑΤΑ (ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ)
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(90,123,'ΜΗΧΑΝΗΜΑΤΑ')

        // //text of ΜΗΧΑΝΗΜΑΤΑ (ΥΠΕΡΓΟΛΑΒΟΙ)
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(300,123,'ΜΗΧΑΝΗΜΑΤΑ')


        // // border of ΠΑΡΑΛΑΒΗ-ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 150, 410, 40)

        // //text of ΠΑΡΑΛΑΝΗ ΥΛΙΚΩΝ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,158,'ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ')



        // // split text ΠΑΡΑΛΑBΗ ΥΛΙΚΩΝ
        // const textlinesMaterial = book.setFont('Arialbd')
        // .setTextColor(40, 66, 131)
        // .setFontSize(8)
        // .splitTextToSize(convertMaterial, 195)
        // book.text(25, 166, textlinesMaterial)



        // //text of ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(230,158,'ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ')



        // // split text ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        // const textlinesRemoval = book.setFont('Arialbd')
        // .setTextColor(40, 66, 131)
        // .setFontSize(8)
        // .splitTextToSize(convertRemoval, 195)
        // book.text(230, 166, textlinesRemoval)



        // // border of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 190, 410, 10)
        // book.rect(20, 190, 410, 225)
        // book.rect(20.5, 190.5, 409, 9, 'F')


        // //text of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,198,'ΠΕΡΙΓΡΑΦΗ/ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ - ΠΑΡΑΤΗΡΗΣΕΙΣ')


        // // split text ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        // const textlinesDailyWorks = book.setFont('Arialbd')
        // .setTextColor(40, 66, 131)
        // .setFontSize(8)
        // .splitTextToSize(convertDailyWorks, 400)
        // book.text(25, 208, textlinesDailyWorks)



        // // border of ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 410, 410, 10)
        // book.rect(20, 410, 410, 55)
        // book.rect(20.5, 410.5, 409, 9, 'F')


        // //text of ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,418,'ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ')
        // book.setFontSize(8)
        // book.text(310,428,'ΥΠΟΓΡΑΦΗ ΕΠΙΒΛΕΠΟΝΤΑ ΜΗΧΑΝΙΚΟΥ')
        // //book.addImage(value.signature,'PNG', 310, 430, 115, 35)

        // // split text ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        // const textlinesInstructions = book.setFont('Arialbd')
        // .setTextColor(40, 66, 131)
        // .setFontSize(8)
        // .splitTextToSize(convertInstructions, 280)
        // book.text(25, 428, textlinesInstructions)



        // // border of ΥΠΕΡΩΡΙΑΚΗ ΕΡΓΑΣΙΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 465, 410, 10)
        // book.rect(20, 465, 410, 35)
        // book.rect(20.5, 465.5, 409, 9, 'F')


        // //text of ΥΠΕΡΩΡΙΑΚΗ ΕΡΓΑΣΙΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,473,'ΥΠΕΡΩΡΙΑΚΗ ΕΡΓΑΣΙΑ')


        // // split text ΥΠΕΡΩΡΙΑΚΗ ΕΡΓΑΣΙΑ
        // if(convertOvertime.length > 358){
        //     const textlinesOvertime = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(6.5)
        //     .splitTextToSize(convertOvertime, 400)
        //     book.text(25, 483, textlinesOvertime)
        // }else{
        //     const textlinesOvertime = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertOvertime, 400)
        //     book.text(25, 483, textlinesOvertime)
        // }



        // // border of ΕΡΓΑΣΙΕΣ ΜΕ ΑΠΟΛΟΓΙΣΜΟ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 500, 410, 10)
        // book.rect(20, 500, 410, 35)
        // book.rect(20.5, 500.5, 409, 9, 'F')


        // //text of ΕΡΓΑΣΙΕΣ ΜΕ ΑΠΟΛΟΓΙΣΜΟ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,508,'ΕΡΓΑΣΙΕΣ ΜΕ ΑΠΟΛΟΓΙΣΜΟ')

        // // split text ΕΡΓΑΣΙΕΣ ΜΕ ΑΠΟΛΟΓΙΣΜΟ
        // if(convertSignificantWorks.length > 358){
        //     const textlinesSignificantWorks = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(7)
        //     .splitTextToSize(convertSignificantWorks, 400)
        //     book.text(25, 518, textlinesSignificantWorks)
        // }else{
        //     const textlinesSignificantWorks = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertSignificantWorks, 400)
        //     book.text(25, 518, textlinesSignificantWorks)
        // }



        // // border of ΕΠΙΣΚΕΠΤΕΣ-ΑΤΥΧΗΜΑΤΑ-ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.setFillColor(211,211,211)
        // book.rect(20, 535, 410, 10)
        // book.rect(20, 535, 410, 48)
        // book.rect(20.5, 535.5, 134, 9, 'F')

        // // border of ΑΤΥΧΗΜΑΤΑ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.rect(155, 535, 135, 48)
        // book.rect(155.5, 535.5, 134, 9, 'F')
        // book.rect(290.5, 535.5, 139, 9, 'F')

        // //text of ΕΠΙΣΚΕΠΤΕΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,543,'ΕΠΙΣΚΕΠΤΕΣ')

        // // split text ΕΠΙΣΚΕΠΤΕΣ here we split the text base of text-length and we reduce the font-size of text
        // if(convertVisitors.length > 175){
        //     const textlinesVisitors = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(7)
        //     .splitTextToSize(convertVisitors, 130)
        //     book.text(23, 552, textlinesVisitors)
        // }else{
        //     const textlinesVisitors = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertVisitors, 130)
        //     book.text(23, 552, textlinesVisitors)
        // }



        // //text of ΑΤΥΧΗΜΑΤΑ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(160,543,'ΑΤΥΧΗΜΑΤΑ')

        // // split text ΑΤΥΧΗΜΑΤΑ
        // if(convertAccidents.length > 175){
        //     const textlinesAccidents = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(7)
        //     .splitTextToSize(convertAccidents, 130)
        //     book.text(158, 552, textlinesAccidents)
        // }else{
        //     const textlinesAccidents = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertAccidents, 130)
        //     book.text(158, 552, textlinesAccidents)
        // }



        // //text of ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(295,543,'ΚΑΘΥΣΤΕΡΗΣΕΙΣ')

        // // split text ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        // if(convertDelays.length > 180){
        //     const textlinesDelays = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(7)
        //     .splitTextToSize(convertDelays, 135)
        //     book.text(293, 552, textlinesDelays)
        // }else{
        //     const textlinesDelays = book.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertDelays, 135)
        //     book.text(293, 552, textlinesDelays)
        // }



        // // border of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        // book.setLineWidth(1)
        // book.setDrawColor(0, 0, 0)
        // book.rect(20, 583, 410, 35)

        // //text of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        // book.setFontSize(10)
        // book.setTextColor(0, 0, 0)
        // book.text(25,593,'ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ')

        // book.addImage(value.signature,'PNG', 140, 585, 150, 30)

        
        // add next blank page
        book.addPage()

    }

        //remove last blank page
        var pageCount = book.internal.getNumberOfPages()
        book.deletePage(pageCount)

        //save the file
        book.save(`Project's Book.pdf`)
    
   }

  

    return (
        <div className="books">

            <div className="mybooks">

                {/* <DiaryForm projectId={projectId} /> */}


                
                <form className="downloadBook" onSubmit={createBook}>
                    <div className="diariesHistory">
                        <p>ΙΣΤΟΡΙΚΟ ΚΑΤΑΧΩΡΗΣΕΩΝ:</p>
                    
                        <button><strong>Download Diary Book</strong></button>
                    </div>
                   
                </form>


                {diaries && diaries.map((diary) => (
                   <DiaryDetails key={diary._id} diary={diary} projectId={projectId} />
                ))}
                {loading && (
                    Loader()
                )}

                <ContactInfo/>

                <Footer/>

            </div>

        </div>
    )
}

export default Book