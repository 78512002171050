import { useEffect } from 'react'
import { useDiariesContext } from '../hooks/useDiariesContext'
import { useAuthContext } from '../hooks/useAuthContext'
import {useParams} from 'react-router-dom'

// components
import DiaryDetails from '../components/DiaryDetails'
import DiaryForm from '../components/DiaryForm'
import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import {BACKEND_URI} from "../config";

const Diaries = () => {
   const {diaries, dispatch} = useDiariesContext()
   const {user} = useAuthContext()
   const {projectId} = useParams()
   //console.log(useParams())

   useEffect(() => {
    const fetchDiaries = async () => {
        const response = await fetch(`${BACKEND_URI}/api/diaries/mydiaries?projectId=${projectId}`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (response.ok){
            dispatch({type: 'SET_DIARIES', payload: json})
        }
    }
    if(user){
        fetchDiaries()
    }

   }, [dispatch, user, projectId])

    return (
        <div className="diaries">

            <div className="mydiaries">

                <DiaryForm projectId={projectId} />


                <p className="diariesHistory">ΙΣΤΟΡΙΚΟ ΚΑΤΑΧΩΡΗΣΕΩΝ:</p>


                {diaries && diaries.map((diary) => (
                   <DiaryDetails key={diary._id} diary={diary} />
                ))}

                <ContactInfo/>

                <Footer/>

            </div>

        </div>
    )
}

export default Diaries
