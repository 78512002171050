import { useEffect, useState } from 'react'
import { useProjectsContext } from '../hooks/useProjectsContext'
import { useAuthContext } from '../hooks/useAuthContext'

// components
import ProjectDetails from '../components/ProjectDetails'
import ProjectForm from '../components/ProjectForm'
import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import {BACKEND_URI} from "../config"
import Loader from "../hooks/useLoader"


const Projects = () => {

   const {projects, dispatch} = useProjectsContext()
   const {user} = useAuthContext()
   const [loading, setLoading] = useState(false)


   useEffect(() => {

        const fetchProjects = async () => {
            setLoading(true)
            const response = await fetch(`${BACKEND_URI}/api/projects/myprojects`, {
                headers: {
                'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok){
                dispatch({type: 'SET_PROJECTS', payload: json})
                setLoading(false)
            }
            
        }
        if(user){

            fetchProjects()

        }

   }, [dispatch, user])


    return (
        <div className="projects">

            <div className="myprojects">

                <ProjectForm />

                {(!loading) && projects && projects.map((project) => (
                   <ProjectDetails key={project._id} project={project} />
                ))}
                {loading && (
                    Loader()
                )}

                <ContactInfo/>

                <Footer/>

            </div>

        </div>
    )
}

export default Projects
