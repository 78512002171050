import { createContext, useReducer } from 'react'

export const StaffContext = createContext()

export const staffReducer = (state, action) => {
    switch (action.type) {
        case 'SET_STAFF':
            return{
                staffs: action.payload
            }
        case 'CREATE_STAFF':
            return{
                staffs: [action.payload, ...state.staffs]
            }
        case 'DELETE_STAFF':
            return{
                staffs: state.staffs.filter((w) => w._id !== action.payload._id)
            }
        default:
            return state
    }
} 

export const StaffContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(staffReducer, {
        staffs: null
    })

    console.log('StaffContext state: ', state)

    return(
        <StaffContext.Provider value={{...state, dispatch}}>
            { children }
        </StaffContext.Provider>
    )
}