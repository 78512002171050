import { useState } from 'react'
import { useSignup } from "../hooks/useSignup"
import Loader from "../hooks/useLoader"

const Signup = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {signup, error, isLoading} = useSignup()
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await signup(email, password)
        setLoading(false)
    }

    return (
      <div className="signdUp">
        {loading && (
          Loader()
        )}
        <form className="signup" onSubmit={handleSubmit}>
            <h3>Sign Up</h3>
            <br />
            <div>
              <label>Email:</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <label>Password:</label>
              <input
                placeholder="Enter at least 8 characters"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="passwordConditions">
              <li>* 1 uppercase</li>
              <li>* 1 lowercase</li>
              <li>* 1 number</li>
              <li>* 1 special character</li>
            </div>
            <br />
            <button disabled={isLoading}>Submit</button>
            <br />
            <p>By creating an account you agree to our <strong>Terms & Privacy</strong></p>
            
            {error && <div className="error">{error}</div>}
        </form>
      </div>
    )
}

export default Signup