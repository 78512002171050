import {Link} from 'react-router-dom'

const Footer = () => {
  
      return(
        <footer>
            <nav>
                <div className="nav-links-container">
                    <ul className="nav-links-footer">
                        <Link to="/about">About</Link>
                        <Link to="/myprojects">Projects</Link>
                        <Link to="/contact">Contact</Link>
                    </ul>
                </div>
            </nav>
            <p>&#169; 2024 <Link to="https://www.facebook.com/meshcivilengineers"><b>Mesh Civil Engineers</b></Link>. All Rights Reserved.</p>
        </footer> 
      )
  }
  
  export default Footer