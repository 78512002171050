export const useHamburgerMenu = () => {

    const hamburgermenu = () => {
        const menu = document.querySelector(".menu-links")
        const icon = document.querySelector(".hamburger-icon")
        menu.classList.toggle("open")
        icon.classList.toggle("open")
    }

    return {hamburgermenu}
}