import { useEffect, useState } from 'react'
import { useStaffContext } from '../hooks/useStaffContext'
import { useAuthContext } from '../hooks/useAuthContext'

// components
import StaffDetails from '../components/StaffDetails'
import {BACKEND_URI} from "../config";
//import StaffForm from '../components/StaffForm'

const Staff = () => {
   const {staffs, dispatch} = useStaffContext()
   const {user} = useAuthContext()
   const [selectedMembers, setSelectedMembers] = useState([])

   useEffect(() => {
    const fetchStaff = async () => {
        const response = await fetch(`${BACKEND_URI}/api/staff/mystaff`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (response.ok){
            dispatch({type: 'SET_STAFF', payload: json})
        }
    }
    if(user){
        fetchStaff()
    }

   }, [dispatch, user])

   
   const handleSelect = (isChecked, data) => {
        if(isChecked) {
            setSelectedMembers([...selectedMembers, data])
        } else {
            const indexOfElementToRemove = selectedMembers.indexOf(data)
            selectedMembers.splice(indexOfElementToRemove, 1)
            setSelectedMembers([...selectedMembers])
        }
    }

    return (
        <div className="diaries">
            <p>{selectedMembers}</p>

            <div className="mydiaries">

                {staffs && staffs.map((staff) => (
                   <StaffDetails key={staff._id} staff={staff} handleSelect={handleSelect}/>
                ))}

            </div>

        </div>
    )
}

export default Staff
