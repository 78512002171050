import {Link} from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import { useHamburgerMenu } from '../hooks/useHamburgerMenu'


const Navbar = () => {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const { hamburgermenu } = useHamburgerMenu()

  const handleClick = () => {
    logout()
  }

  //when user click on hamburger menu
  const toggleMenu = () => {
    hamburgermenu()
  }
  

    return(
        <header>
            <div className="container">
              
                <nav className="desktop-nav">
                    <div className="logo">
                        <Link to="/">
                           Daily Site Diary
                        </Link>
                    </div>
                    <ul className="nav-links">
                      <div>
                        <Link to="/about">About</Link>
                        <Link to="/myprojects">Projects</Link>
                        <Link to="/contact">Contact</Link>
                      </div>
                    </ul>
                </nav>
                <nav>
                  <ul className="nav-links-connect">
                    {user && (
                      <div className="connected">
                        <span className="material-symbols-outlined" title={user.email}>account_circle</span>
                        <span onClick={handleClick} className="material-symbols-outlined" title="Sign out">logout</span>
                        {/* <button onClick={handleClick}>Log out</button> */}
                      </div>
                    )}
                    {!user && (
                      <div>
                        <Link to="/login">Login</Link>
                        <Link to="/signup">Signup</Link>
                      </div>
                    )}
                  </ul>
                </nav>

                <nav className="hamburger-nav">
                    <div className="hamburger-menu">
                        <div className="hamburger-icon" onClick= {toggleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    
                        <div className="menu-links">
                            {user && (
                              <div>
                                <Link to="/about" onClick={toggleMenu}>About</Link>
                                <Link to="/myprojects" onClick={toggleMenu}>Projects</Link>
                                <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                                <button onClick={handleClick}>Log out</button>
                              </div>
                            )}
                            {!user && (
                              <div>
                                <Link to="/about" onClick={toggleMenu}>About</Link>
                                <Link to="/myprojects" onClick={toggleMenu}>Projects</Link>
                                <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                                <Link to="/login" onClick={toggleMenu}>Login</Link>
                                <Link to="/signup" onClick={toggleMenu}>Signup</Link>
                              </div>
                            )}
                        </div>
                    </div>
                </nav>

            </div>
        </header>
    )
}

export default Navbar