import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ProjectsContextProvider} from './context/ProjectContext'
import { StaffContextProvider} from './context/StaffContext'
import { DiariesContextProvider} from './context/DiaryContext'
import { EmailsContextProvider} from './context/EmailContext'
import { AuthContextProvider} from './context/AuthContext'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(

  <React.StrictMode>
    <AuthContextProvider>
      <ProjectsContextProvider>
        <StaffContextProvider>
          <DiariesContextProvider>
            <EmailsContextProvider>
              <App />
            </EmailsContextProvider>
          </DiariesContextProvider>
        </StaffContextProvider>  
      </ProjectsContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
  
);


