import { createContext, useReducer } from 'react'

export const EmailsContext = createContext()

export const emailsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_EMAIL':
            return{
                emails: action.payload
            }
        case 'CREATE_EMAIL':
            return{
                emails: [action.payload, state.projects]
            }
        default:
            return state
    }
}

export const EmailsContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(emailsReducer, {
        emails: null
    })

    console.log('EmailContext state: ', state)

    return(
        <EmailsContext.Provider value={{...state, dispatch}}>
            { children }
        </EmailsContext.Provider>
    )
}