import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import {Link} from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import {useEffect} from 'react'

const Home = () => {
    const { user } = useAuthContext()
    

  useEffect(() => {
    
    const canvas = document.getElementById("canvas1");
    const ctx = canvas.getContext('2d');

    canvas.width = window.screen.width;
    canvas.height = window.screen.height;
    
    console.log(canvas)

    let particlesArray;


    //get mouse position
    let mouse = {

        x: null,
        y: null,
        radius: (canvas.height/150)*(canvas.width/150)

    };




    //create Particle
    class Particle {

        constructor (x, y, directionX, directionY, size, color){
    
        this.x = x;
        this.y = y;
        this.directionX = directionX;
        this.directionY = directionY;
        this.size = size;
        this.color = color; 
    
        }
    
    
    
        // method to draw individual particle
        draw(){
            ctx.beginPath();
            ctx.arc(this.x,this.y,this.size,0, Math.PI*2,false);
            ctx.fillStyle = '#1aac83';
            ctx.fill();
        }
    
        update(){
    
            if(this.x>canvas.width||this.x<0){
                this.directionX = -this.directionX;
            }
            if(this.y>canvas.height||this.y<0){
                this.directionY = -this.directionY;
            }
            
            //check collision detection - mouse position/particle position
            let dx = mouse.x - this.x;
            let dy = mouse.y - this.y;
            let distance = Math.sqrt(dx*dx + dy*dy);
            if(distance < mouse.radius + this.size){
    
                if(mouse.x < this.x && this.x < canvas.width - this.size*10){
                    this.x +=10;
                }
    
                if(mouse.x > this.x && this.x > this.size * 10){
                    this.x -=10;
                }
    
                if(mouse.y < this.y && this.y < canvas.height - this.size*10){
                    this.y +=10;
                }
    
                if(mouse.y > this.y && this.y > this.size * 10){
                    this.y -=10;
                }
            }
    
            //move particle
            this.x += this.directionX;
            this.y += this.directionY;
    
            //draw particle
            this.draw();
            
        }
    
    }
 
        //create particle array
        function init(){
        
            particlesArray = [];
            let numberOfParticles = (canvas.height * canvas.width)/11000;
            for(let i = 0; i < numberOfParticles/3; i++){
        
                let size = (Math.random() * 4) + 1;
                let x =(Math.random() *((window.screen.width - size * 2) - (size*2)) + size * 2);
                let y =(Math.random() *((window.screen.height - size * 2) - (size*2)) + size * 2);
        
                let directionX = (Math.random() * 5) - 2.5;
                let directionY = (Math.random() * 5) - 2.5;
                let color = "blue";
        
                particlesArray.push(new Particle(x, y, directionX, directionY, size, color));
            }
        
        }
 
 
        //check if particles are close enough to draw line between them
        function connect(){
            let opacityValue = 1;
            for(let a = 0 ; a < particlesArray.length ; a++){
                for(let b = a; b < particlesArray.length; b++){
                let distance = ((particlesArray[a].x - particlesArray[b].x) * (particlesArray[a].x - particlesArray[b].x)) + ((particlesArray[a].y - particlesArray[b].y) * (particlesArray[a].y - particlesArray[b].y));
                if(distance < (canvas.width/7)*(canvas.width/7)){
                    opacityValue = 1 - (distance/20000);
                    ctx.strokeStyle = 'rgba(26,172,131,' + opacityValue + ')';
                    ctx.lineWidth = 1.5;
                    ctx.beginPath();
                    ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
                    ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
                    ctx.stroke();
                }
                }
            }
        }
 
        //animation loop
        function animate(){
        
            requestAnimationFrame(animate);
        
            ctx.clearRect(0, 0, window.screen.width, window.screen.height);
        
            for(let i = 0; i < particlesArray.length; i++){
        
            particlesArray[i].update();
        
            }
            connect();
        
        }
 
        //resize event
        window.addEventListener('resize',
            function(){
                canvas.width = window.screen.width;
                canvas.height = window.screen.heightinnerHeight;
                mouse.radius = (canvas.height/80)*(canvas.height/80);
                init();
            }
        )
 
        // //mouse out event
        window.addEventListener('mouseout',
            function(){
                mouse.x = undefined;
                mouse.y = undefined;
            }
        )
        
        init();
        animate();

        

  })



    return (
        <div>
        <canvas className="canvas1" id="canvas1"></canvas>
        <div className="home">
            
            


        {!user && (

            <form>

                <h1 className="title">Welcome!</h1>
                <p className="section__text__p1">Improve Site Supervision by keeping Online detailed records of daily activities.</p>
                <br />
                <div className="startnow">
                   <Link to="/login"><button><strong>Start Now</strong></button></Link>
                </div>
                <div className="activeUsers">
                   <span class="material-symbols-outlined">group</span> <p><strong>Active Users: 11</strong></p> 
                </div>
                

            </form>

        )}
        {user && (

            <div>

               <h1 className="title">Connected!</h1>
               <p className="active">{user.email}</p>
               <br />
               <form>

                 <div className="checkProjects">
                    <Link to="/myprojects"><button><strong>Check Projects</strong></button></Link>
                 </div>
                 <div className="activeUsers">
                   <span class="material-symbols-outlined">group</span> <p><strong>Active Users: 11</strong></p>
                </div>
                 
               </form>


            </div>

        )}
            <br />
            {/* <button>Start Now!</button>  */}


        
            <ContactInfo/>

            <Footer/>
          
        </div>
        </div>
    )

}

export default Home
