import { createContext, useReducer } from 'react'

export const DiariesContext = createContext()

export const diariesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DIARIES':
            return{
                diaries: action.payload
            }
        case 'CREATE_DIARY':
            return{
                diaries: [action.payload, ...state.diaries]
            }
        case 'DELETE_DIARY':
            return{
                diaries: state.diaries.filter((w) => w._id !== action.payload._id)
            }
        default:
            return state
    }
}

export const DiariesContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(diariesReducer, {
        diaries: null
    })

    console.log('DiaryContext state: ', state)

    return(
        <DiariesContext.Provider value={{...state, dispatch}}>
            { children }
        </DiariesContext.Provider>
    )
}