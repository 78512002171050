import { useState } from "react"
import { useEmailsContext } from '../hooks/useEmailsContext'
import {BACKEND_URI} from "../config";

const ContactForm = () => {

    const { dispatch } = useEmailsContext()
    const [fullname, setFullname] = useState('')
    const [useremail, setUseremail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])


    const handleSubmit = async (e) => {
        e.preventDefault()

        const email = {fullname, useremail, subject, message}

        const response = await fetch(`${BACKEND_URI}/api/emails/contact`, {
            method: 'POST',
            body: JSON.stringify(email),
            headers: {
                'Content-Type': 'application/json',

            }
        })
        const json = await response.json()

        if (!response.ok){
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setFullname('')
            setUseremail('')
            setSubject('')
            setMessage('')
            setError(null)
            setEmptyFields([])
            console.log('new email added', json)
            dispatch({type: 'CREATE_EMAIL', payload:json})
        }

    }

    return (
        <form className="email-form" onSubmit={handleSubmit}>
            <h3>Get in Touch</h3>
            <br />
            <input
                type="text"
                placeholder="Full name"
                //name="fullname"
                onChange={(e) => setFullname(e.target.value)}
                value={fullname}
                className={emptyFields.includes('fullname') ? 'error' : ''}
            />
            <input
                type="email"
                placeholder="E-mail"
                //name="useremail"
                onChange={(e) => setUseremail(e.target.value)}
                value={useremail}
                className={emptyFields.includes('useremail') ? 'error' : ''}
            />
            <input
                type="text"
                placeholder="Subject"
                //name="subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                className={emptyFields.includes('subject') ? 'error' : ''}
            />
            <textarea
                className="message"
                placeholder="Write your message..."
                //name="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}>
                className={emptyFields.includes('message') ? 'error' : ''}
            </textarea>

            <button>Submit</button>
            {error && <div className="error">{error}</div>}

        </form>
    )

}

export default ContactForm
