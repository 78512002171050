import React, { useState } from 'react'
import {useEffect} from "react"
import { useDiariesContext } from '../hooks/useDiariesContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useRef } from "react"
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import SignatureCanvas from 'react-signature-canvas'
import { Link } from 'react-router-dom'
import {BACKEND_URI} from "../config";
import {useProjectsContext} from "../hooks/useProjectsContext";


//Project Form
const DiaryForm = ({ projectId }) => {

    const { dispatch } = useDiariesContext()
    const { user } = useAuthContext()
    const { projects } = useProjectsContext()
    const [projectTitle, setProjectTitle] = useState(null)

    useEffect(() => {
        if (user && projects) {
            const selectedProject = projects.find(p => p._id === projectId)
            if (!selectedProject) {
                throw Error('the provided projectTitle could not be found in the context')
            } else {
                setProjectTitle(selectedProject.title)
            }
        } else {
            const response = fetch(`${BACKEND_URI}/api/projects/myprojects/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(async (res) => {
                const json = await res.json()
                setProjectTitle(json.title)
            })
        }

    }, [user, projects])

    //WHEATHER-WIND-TEMPERATURE
    const [weather, setWeather] = useState('')
    const [wind, setWind] = useState('')
    const [tempMin, setTempMin] = useState('')
    const [tempMax, setTempMax] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    const dropdownWeather = useRef()
    const dropdownWind = useRef()
    const dropdownTempMin = useRef()
    const dropdownTempMax = useRef()
    //DATE & TIME
    const [selectdate, setSelectDate] = useState(null)
    const [startTime, setStartTime] = useState('')
    const [finishTime, setFinishTime] = useState('')
    const [breakTime, setBreakTime] = useState('')
    const dropdownStartTime = useRef()
    const dropdownFinishTime = useRef()
    const dropdownBreakTime = useRef()
    //MAIN CONTRACTOR - STAFF
    const [kaMC, setKaMC] = useState('0')
    const [siMC, setSiMC] = useState('0')
    const [ktMC, setKtMC] = useState('0')
    const [erMC, setErMC] = useState('0')
    const [ydMC, setYdMC] = useState('0')
    const [ilMC, setIlMC] = useState('0')
    const [psMC, setPsMC] = useState('0')
    const [gyMC, setGyMC] = useState('0')
    const [alMC, setAlMC] = useState('0')
    const [peMC, setPeMC] = useState('0')
    const [metMC, setMetMC] = useState('0')
    const [poMC, setPoMC] = useState('0')
    const [moMC, setMoMC] = useState('0')
    const [meMC, setMeMC] = useState('0')
    const [epMC, setEpMC] = useState('0')
    const [odMC, setOdMC] = useState('0')
    const [miMC, setMiMC] = useState('0')
    
    //SUBCONTRACTOR - STAFF
    const [kaSub, setKaSub] = useState('0')
    const [siSub, setSiSub] = useState('0')
    const [ktSub, setKtSub] = useState('0')
    const [erSub, setErSub] = useState('0')
    const [ydSub, setYdSub] = useState('0')
    const [ilSub, setIlSub] = useState('0')
    const [psSub, setPsSub] = useState('0')
    const [gySub, setGySub] = useState('0')
    const [alSub, setAlSub] = useState('0')
    const [peSub, setPeSub] = useState('0')
    const [metSub, setMetSub] = useState('0')
    const [poSub, setPoSub] = useState('0')
    const [moSub, setMoSub] = useState('0')
    const [meSub, setMeSub] = useState('0')
    const [epSub, setEpSub] = useState('0')
    const [odSub, setOdSub] = useState('0')
    const [miSub, setMiSub] = useState('0')
    //MAIN CONTRACTOR - MACHINERIES
    const [ekMC, setEkMC] = useState('0')
    const [foMC, setFoMC] = useState('0')
    const [odosMC, setOdosMC] = useState('0')
    const [anMC, setAnMC] = useState('0')
    const [vaMC, setVaMC] = useState('0')
    const [doMC, setDoMC] = useState('0')
    const [amMC, setAmMC] = useState('0')
    const [flMC, setFlMC] = useState('0')
    const [tiMC, setTiMC] = useState('0')
    const [viMC, setViMC] = useState('0')
    const [geMC, setGeMC] = useState('0')
    const [syMC, setSyMC] = useState('0')
    const [ksMC, setKsMC] = useState('0')
    //SUBCONTRACTOR - MACHINERIES
    const [ekSub, setEkSub] = useState('0')
    const [foSub, setFoSub] = useState('0')
    const [odosSub, setOdosSub] = useState('0')
    const [anSub, setAnSub] = useState('0')
    const [vaSub, setVaSub] = useState('0')
    const [doSub, setDoSub] = useState('0')
    const [amSub, setAmSub] = useState('0')
    const [flSub, setFlSub] = useState('0')
    const [tiSub, setTiSub] = useState('0')
    const [viSub, setViSub] = useState('0')
    const [geSub, setGeSub] = useState('0')
    const [sySub, setSySub] = useState('0')
    const [ksSub, setKsSub] = useState('0')



    //RECEIPT & REMOVAL MATERIAL
    const [material, setMaterial] = useState('')
    const [removal, setRemoval] = useState('')
    //MAIN WORKS
    const [dailyWorks, setDailyWorks] = useState('')
    //INSTRUCTIONS
    const [instructions, setInstructions] = useState('')
    //VISITORS-ACCIDENTS-DELAYS
    const [visitors, setVisitors] = useState('')
    const [accidents, setAccidents] = useState('')
    const [delays, setDelays] = useState('')
    //SIGNATURE
    const [sign, setSign] = useState()
    const [signature, setSignature] = useState('')


    //Dropdown form Section2
    const [showSection2Form, setShowSection2Form] = useState(false)

    //onClick handle Section2 Form
    const handleSection2Form = () => {

        setShowSection2Form(!showSection2Form)
        setShowSection3Form(false)
        setShowSection4Form(false)
        setShowSection5Form(false)
        setShowSection6Form(false)
        setShowSection7Form(false)
        setShowSection8Form(false)

    }


    //Dropdown form Section3
    const [showSection3Form, setShowSection3Form] = useState(false)

    //onClick handle Section3 Form
    const handleSection3Form = () => {

        setShowSection3Form(!showSection3Form)
        setShowSection2Form(false)
        setShowSection4Form(false)
        setShowSection5Form(false)
        setShowSection6Form(false)
        setShowSection7Form(false)
        setShowSection8Form(false)

    }


    //Dropdown form Section4
    const [showSection4Form, setShowSection4Form] = useState(false)

    //onClick handle Section4 Form
    const handleSection4Form = () => {

        setShowSection4Form(!showSection4Form)
        setShowSection2Form(false)
        setShowSection3Form(false)
        setShowSection5Form(false)
        setShowSection6Form(false)
        setShowSection7Form(false)
        setShowSection8Form(false)

    }


     //Dropdown form Section5
     const [showSection5Form, setShowSection5Form] = useState(false)

     //onClick handle Section5 Form
     const handleSection5Form = () => {

        setShowSection5Form(!showSection5Form)
        setShowSection2Form(false)
        setShowSection3Form(false)
        setShowSection4Form(false)
        setShowSection6Form(false)
        setShowSection7Form(false)
        setShowSection8Form(false)

     }

    //Dropdown form Section6
    const [showSection6Form, setShowSection6Form] = useState(false)

    //onClick handle Section6 Form
    const handleSection6Form = () => {

        setShowSection6Form(!showSection6Form)
        setShowSection2Form(false)
        setShowSection3Form(false)
        setShowSection4Form(false)
        setShowSection5Form(false)
        setShowSection7Form(false)
        setShowSection8Form(false)

    }


    //Dropdown form Section7
    const [showSection7Form, setShowSection7Form] = useState(false)

    //onClick handle Section7 Form
    const handleSection7Form = () => {

        setShowSection7Form(!showSection7Form)
        setShowSection2Form(false)
        setShowSection3Form(false)
        setShowSection4Form(false)
        setShowSection5Form(false)
        setShowSection6Form(false)
        setShowSection8Form(false)

    }


    //Dropdown form Section8
    const [showSection8Form, setShowSection8Form] = useState(false)

    //onClick handle Section8 Form
    const handleSection8Form = () => {

        setShowSection8Form(!showSection8Form)
        setShowSection2Form(false)
        setShowSection3Form(false)
        setShowSection4Form(false)
        setShowSection5Form(false)
        setShowSection6Form(false)
        setShowSection7Form(false)

    }


    //Diary Items for Weather-type
    const itemWeatherTypes = {

        "0": "ΒΡΟΧΕΡΟΣ",
        "1": "ΑΙΘΡΙΟΣ",
        "2": "ΚΑΥΣΩΝΑΣ",
        "3": "ΠΑΓΕΤΟΣ",
        "4": "ΝΕΦΩΔΗΣ",

    }

    //Diary Items for Wind-type
    const itemWindTypes = {

        "0": "ΚΑΘΟΛΟΥ",
        "1": "ΜΕΤΡΙΟΙ",
        "2": "ΔΥΝΑΤΟΙ",

    }

    //Diary Items for Min. Temperature
    const itemTempMin = {

        "0": "0 °C",
        "1": "1 °C",
        "2": "2 °C",
        "3": "3 °C",
        "4": "4 °C",
        "5": "5 °C",
        "6": "6 °C",
        "7": "7 °C",
        "8": "8 °C",
        "9": "9 °C",
        "10": "10 °C",
        "11": "11 °C",
        "12": "12 °C",
        "13": "13 °C",
        "14": "14 °C",
        "15": "15 °C",
        "16": "16 °C",
        "17": "17 °C",
        "18": "18 °C",
        "19": "19 °C",
        "20": "20 °C",
        "21": "21 °C",
        "22": "22 °C",
        "23": "23 °C",
        "24": "24 °C",
        "25": "25 °C",
        "26": "26 °C",
        "27": "27 °C",
        "28": "28 °C",
        "29": "29 °C",
        "30": "30 °C",
        "31": "31 °C",
        "32": "32 °C",
        "33": "33 °C",
        "34": "34 °C",
        "35": "35 °C",
        "36": "36 °C",
        "37": "37 °C",
        "38": "38 °C",
        "39": "39 °C",
        "40": "40 °C",
        "41": "41 °C",
        "42": "42 °C",
        "43": "43 °C",
        "44": "44 °C",
        "45": "45 °C",

    }

    //Diary Items for Max Temperature
    const itemTempMax = {

        "0": "0 °C",
        "1": "1 °C",
        "2": "2 °C",
        "3": "3 °C",
        "4": "4 °C",
        "5": "5 °C",
        "6": "6 °C",
        "7": "7 °C",
        "8": "8 °C",
        "9": "9 °C",
        "10": "10 °C",
        "11": "11 °C",
        "12": "12 °C",
        "13": "13 °C",
        "14": "14 °C",
        "15": "15 °C",
        "16": "16 °C",
        "17": "17 °C",
        "18": "18 °C",
        "19": "19 °C",
        "20": "20 °C",
        "21": "21 °C",
        "22": "22 °C",
        "23": "23 °C",
        "24": "24 °C",
        "25": "25 °C",
        "26": "26 °C",
        "27": "27 °C",
        "28": "28 °C",
        "29": "29 °C",
        "30": "30 °C",
        "31": "31 °C",
        "32": "32 °C",
        "33": "33 °C",
        "34": "34 °C",
        "35": "35 °C",
        "36": "36 °C",
        "37": "37 °C",
        "38": "38 °C",
        "39": "39 °C",
        "40": "40 °C",
        "41": "41 °C",
        "42": "42 °C",
        "43": "43 °C",
        "44": "44 °C",
        "45": "45 °C",

    }

    //Diary Items for Starting Time
    const itemStartTime = {

        "0": "07:00",
        "1": "07:30",
        "2": "08:00",
        "3": "08:30",
        "4": "09:00",
        "5": "09:30",
        "6": "10:00",
        "7": "10:30",
        "8": "11:00",
        "9": "11:30",
        "10": "12:00",
        "11": "12:30",
        "12": "13:00",
        "13": "13:30",
        "14": "14:00",
        "15": "14:30",
        "16": "15:00",
        "17": "15:30",
        "18": "16:00",
        "19": "16:30",
        "20": "17:00",
        "21": "17:30",
        "22": "18:00",
        "23": "18:30",
        "24": "19:00",
        "25": "19:30",
        "26": "20:00",
        "27": "20:30",
        "28": "21:00",
        "29": "21:30",
        "30": "22:00",
        "31": "22:30",
        "32": "23:00",
        "33": "23:30",
        "34": "00:00",

    }

    //Diary Items for Starting Time
    const itemBreakTime = {

        "0": "15'",
        "1": "30'",
        "2": "45'",
        "3": "60'",

    }

    //Diary Items for Starting Time
    const itemFinishTime = {

        "0": "07:00",
        "1": "07:30",
        "2": "08:00",
        "3": "08:30",
        "4": "09:00",
        "5": "09:30",
        "6": "10:00",
        "7": "10:30",
        "8": "11:00",
        "9": "11:30",
        "10": "12:00",
        "11": "12:30",
        "12": "13:00",
        "13": "13:30",
        "14": "14:00",
        "15": "14:30",
        "16": "15:00",
        "17": "15:30",
        "18": "16:00",
        "19": "16:30",
        "20": "17:00",
        "21": "17:30",
        "22": "18:00",
        "23": "18:30",
        "24": "19:00",
        "25": "19:30",
        "26": "20:00",
        "27": "20:30",
        "28": "21:00",
        "29": "21:30",
        "30": "22:00",
        "31": "22:30",
        "32": "23:00",
        "33": "23:30",
        "34": "00:00",

    }

    //dropdown Select Weather of Diary Form
    const handleWeatherChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemWeatherTypes[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Τύπο Καιρού")){

            setWeather(itemTypeText)

        }
    }

    //dropdown Select Wind of Diary Form
    const handleWindChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemWindTypes[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Τύπο Ανέμων")){

            setWind(itemTypeText)

        }
    }

    //dropdown Select Min Temperature of Diary Form
    const handleTempMinChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemTempMin[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Θερμοκρασία")){

            setTempMin(itemTypeText)

        }
    }

    //dropdown Select Max Temperature of Diary Form
    const handleTempMaxChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemTempMax[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Θερμοκρασία")){

            setTempMax(itemTypeText)

        }
    }

    //dropdown Select Date
    const handleDateChange = (date) => {

        if(!(date === "Επιλέξτε Ημερομηνία")){
            setSelectDate(date)
        }

    }

    //dropdown Select Start Time of Diary Form
    const handleStartTimeChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemStartTime[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Ώρα")){

            setStartTime(itemTypeText)

        }
    }

    //dropdown Select Finish Time of Diary Form
    const handleFinishTimeChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemFinishTime[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Ώρα")){

            setFinishTime(itemTypeText)

        }
    }

    //dropdown Select Break Time of Diary Form
    const handleBreakTimeChange = (e) => {

        e.preventDefault()
        const itemTypeId = e.target.value
        const itemTypeText  = itemBreakTime[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Λεπτά")){

            setBreakTime(itemTypeText)

        }
    }


    const handleSignClear = (e) => {

        e.preventDefault()
        sign.clear()
        setSignature('')

    }

    const handleSignGenerate = (e) => {

        e.preventDefault()
        setSignature(sign.getTrimmedCanvas().toDataURL('image/png'))

    }


const handleDiarySubmit = async (e) => {

    e.preventDefault()
    if(!user){
        setError('You must be logged in')

        return
    }


    const diary = {

        selectdate,
        startTime, finishTime, breakTime,
        kaMC, siMC, ktMC, erMC, ydMC, ilMC, psMC, gyMC, alMC, peMC, metMC, poMC, moMC, meMC, epMC, odMC, miMC,
        ekMC, foMC, odosMC, anMC, vaMC, doMC, amMC, flMC, tiMC, viMC, geMC, syMC, ksMC,
        kaSub, siSub, ktSub, erSub, ydSub, ilSub, psSub, gySub, alSub, peSub, metSub, poSub, moSub, meSub, epSub, odSub, miSub,
        ekSub, foSub, odosSub, anSub, vaSub, doSub, amSub, flSub, tiSub, viSub, geSub, sySub, ksSub,
        weather, wind, tempMin, tempMax,
        material, removal,
        dailyWorks,
        instructions,
        visitors, accidents, delays, ...{project_id:projectId, project_title: projectTitle}, signature

    }

    const response = await fetch(`${BACKEND_URI}/api/diaries/mydiaries/`, {
        method: 'POST',
        body: JSON.stringify(diary),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
        }
    })
    const json = await response.json()

    if (!response.ok){
        setError(json.error)
        setEmptyFields(json.emptyFields)
    }
    if (response.ok) {
        setWeather('')
        setWind('')
        setTempMin('')
        setTempMax('')
        setSelectDate(null)
        setStartTime('')
        setFinishTime('')
        setBreakTime('')
        //ΠΡΟΣΩΠΙΚΟ ΕΡΓΟΛΑΒΟΥ
        setKaMC('0')
        setSiMC('0')
        setKtMC('0')
        setErMC('0')
        setYdMC('0')
        setIlMC('0')
        setPsMC('0')
        setGyMC('0')
        setAlMC('0')
        setPeMC('0')
        setMetMC('0')
        setPoMC('0')
        setMoMC('0')
        setMeMC('0')
        setEpMC('0')
        setOdMC('0')
        setMiMC('0')
        //ΠΡΟΣΩΠΙΚΟ ΥΠΕΡΓΟΛΑΒΟΥ
        setKaSub('0')
        setSiSub('0')
        setKtSub('0')
        setErSub('0')
        setYdSub('0')
        setIlSub('0')
        setPsSub('0')
        setGySub('0')
        setAlSub('0')
        setPeSub('0')
        setMetSub('0')
        setPoSub('0')
        setMoSub('0')
        setMeSub('0')
        setEpSub('0')
        setOdSub('0')
        setMiSub('0')
        //ΜΗΧΑΝΗΜΑΤΑ ΕΡΓΟΛΑΒΟΥ
        setEkMC('0')
        setFoMC('0')
        setOdosMC('0')
        setAnMC('0')
        setVaMC('0')
        setDoMC('0')
        setAmMC('0')
        setFlMC('0')
        setTiMC('0')
        setViMC('0')
        setGeMC('0')
        setSyMC('0')
        setKsMC('0')
        //ΜΗΧΑΝΗΜΑΤΑ ΥΠΕΡΓΟΛΑΒΟΥ
        setEkSub('0')
        setFoSub('0')
        setOdosSub('0')
        setAnSub('0')
        setVaSub('0')
        setDoSub('0')
        setAmSub('0')
        setFlSub('0')
        setTiSub('0')
        setViSub('0')
        setGeSub('0')
        setSySub('0')
        setKsSub('0')
        //ΥΛΙΚΑ
        setMaterial('')
        setRemoval('')
        setDailyWorks('')
        setInstructions('')
        setVisitors('')
        setAccidents('')
        setDelays('')
        sign.clear()
        setSignature('')
        setError(null)
        setEmptyFields([])
        setTimeout(() => {dropdownWeather.current.value = ""}, 500) // <- simulating some process...
        setTimeout(() => {dropdownWind.current.value = ""}, 500)
        setTimeout(() => {dropdownTempMin.current.value = ""}, 500)
        setTimeout(() => {dropdownTempMax.current.value = ""}, 500)
        setTimeout(() => {dropdownStartTime.current.value = ""}, 500)
        setTimeout(() => {dropdownFinishTime.current.value = ""}, 500)
        setTimeout(() => {dropdownBreakTime.current.value = ""}, 500)
        console.log('new diary added', json)
        dispatch({type: 'CREATE_DIARY', payload:json})
    }


}


useEffect(() => {


    document.getElementById('saveSignButton').addEventListener('click',function() {
        document.querySelector('.sigCanvas').style.border = '2px solid #1aac83';
      })
        document.getElementById('clearSignButton').addEventListener('click',function() {
        document.querySelector('.sigCanvas').style.border = '2px solid lightgrey';
      })

    const tabsBox = document.querySelector('.tabs-box'),
    arrowIcons = document.querySelectorAll(".icon i");

    arrowIcons.forEach(icon => {
        icon.addEventListener("click", () => {
            tabsBox.scrollLeft += icon.id === "left" ? -190 : 190;
        });
    });

    let isDragging = false;

    const dragging = (e) => {
        if(!isDragging) return;
        tabsBox.classList.add("dragging");
        tabsBox.scrollLeft -= e.movementX;
    }

    const dragStop = () => {
        isDragging = false;
        tabsBox.classList.remove("dragging");
    }

    tabsBox.addEventListener("mousedown", () => isDragging = true);
    tabsBox.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);

    const constrainInput = (event) => { 
        event.target.value = event.target.value.replace(/[\r\n\v]+/g, '')
      }
      
      document.querySelectorAll('.textArea').forEach(el => {
        el.addEventListener('keyup', constrainInput)
      })
})




return (
 <div className="diaryForm">


    <form className="createDiaryForm" onSubmit={handleDiarySubmit}>

       <div className="wrapper">
            <div className="icon"><i id="left" className="fa-solid fa-angle-left"></i></div>
                <ul className="tabs-box">

                    <Link className="tab" onClick={handleSection2Form}>ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ</Link>

                    <Link className="tab" onClick={handleSection3Form}>ΥΠΕΡΓΟΛΑΒΟΙ</Link>

                    <Link className="tab" onClick={handleSection4Form}>ΠΑΡΑΛΑΒΗ & ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ</Link>

                    <Link className="tab" onClick={handleSection5Form}>ΚΑΘΥΣΤΕΡΗΣΕΙΣ</Link>

                    <Link className="tab" onClick={handleSection6Form}>ΑΤΥΧΗΜΑΤΑ</Link>

                    <Link className="tab" onClick={handleSection7Form}>ΕΠΙΣΚΕΠΤΕΣ</Link>

                    <Link className="tab" onClick={handleSection8Form}>ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ</Link>

                </ul>
            <div className="icon"><i id="right" className="fa-solid fa-angle-right"></i></div>

        </div>

        <form>
            {error &&
               <div className="error">{error}</div>
            }
        </form>

        <br />


    {showSection2Form &&
        //ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ
        <div className="mainContractor">

            <div className="contractor">

               <h2>ΚΥΡΙΟΣ ΕΡΓΟΛΑΒΟΣ</h2>

            </div>
            <br />
            <div className="contractor">

               <h2>ΠΡΟΣΩΠΙΚΟ</h2>
               <span className="material-symbols-outlined">engineering</span>

            </div>
            <div className="Section2">
                <div>
                    <h3>Καλουψιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setKaMC(e.target.value)}
                        value={kaMC}
                        className={emptyFields.includes('kaMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Σιδεράς</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setSiMC(e.target.value)}
                        value={siMC}
                        className={emptyFields.includes('siMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Κτίστης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setKtMC(e.target.value)}
                        value={ktMC}
                        className={emptyFields.includes('ktMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Εργάτης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setErMC(e.target.value)}
                        value={erMC}
                        className={emptyFields.includes('erMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Υδραυλικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setYdMC(e.target.value)}
                        value={ydMC}
                        className={emptyFields.includes('ydMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Ηλεκτρολόγος</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setIlMC(e.target.value)}
                        value={ilMC}
                        className={emptyFields.includes('ilMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Ψυκτικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPsMC(e.target.value)}
                        value={psMC}
                        className={emptyFields.includes('psMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Γυψοσανιδάς</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setGyMC(e.target.value)}
                        value={gyMC}
                        className={emptyFields.includes('gyMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αλουμινιτζιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setAlMC(e.target.value)}
                        value={alMC}
                        className={emptyFields.includes('alMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Πελεκάνος</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPeMC(e.target.value)}
                        value={peMC}
                        className={emptyFields.includes('peMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μεταλλικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMetMC(e.target.value)}
                        value={metMC}
                        className={emptyFields.includes('metMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Πογιατζιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPoMC(e.target.value)}
                        value={poMC}
                        className={emptyFields.includes('poMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μονωτής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMoMC(e.target.value)}
                        value={moMC}
                        className={emptyFields.includes('moMC') ? 'error' : ''}
                    />
                </div>
                <div >
                    <h3>Μηχανικός Εργοταξίου</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMeMC(e.target.value)}
                        value={meMC}
                        className={emptyFields.includes('meMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Επιστάτης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setEpMC(e.target.value)}
                        value={epMC}
                        className={emptyFields.includes('epMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Οδηγός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setOdMC(e.target.value)}
                        value={odMC}
                        className={emptyFields.includes('odMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μηχανοδηγός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMiMC(e.target.value)}
                        value={miMC}
                        className={emptyFields.includes('miMC') ? 'error' : ''}
                    />
                </div>
                
                
            </div>

            <br/>

            <div className="machineries">

                <h2>ΜΗΧΑΝΗΜΑΤΑ</h2>
                <span className="material-symbols-outlined">front_loader</span>

            </div>
            <div className="Section2_M">
                <div>
                    <h3>Εκσκαφέας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setEkMC(e.target.value)}
                        value={ekMC}
                        className={emptyFields.includes('ekMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Φορτηγό</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setFoMC(e.target.value)}
                        value={foMC}
                        className={emptyFields.includes('foMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Οδοστρωτήρας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setOdosMC(e.target.value)}
                        value={odosMC}
                        className={emptyFields.includes('odosMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αντλία</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setAnMC(e.target.value)}
                        value={anMC}
                        className={emptyFields.includes('anMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Βαρέλα</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setVaMC(e.target.value)}
                        value={vaMC}
                        className={emptyFields.includes('vaMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Δονητής</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setDoMC(e.target.value)}
                        value={doMC}
                        className={emptyFields.includes('doMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αναμικτήρας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setAmMC(e.target.value)}
                        value={amMC}
                        className={emptyFields.includes('amMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Παλετοφόρο</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setFlMC(e.target.value)}
                        value={flMC}
                        className={emptyFields.includes('flMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Τηλεσκοπικό</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setTiMC(e.target.value)}
                        value={tiMC}
                        className={emptyFields.includes('tiMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Βίντζι</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setViMC(e.target.value)}
                        value={viMC}
                        className={emptyFields.includes('viMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Γερανός</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setGeMC(e.target.value)}
                        value={geMC}
                        className={emptyFields.includes('geMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Συμπιεστής</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setSyMC(e.target.value)}
                        value={syMC}
                        className={emptyFields.includes('syMC') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Κομπρεσσόρος</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setKsMC(e.target.value)}
                        value={ksMC}
                        className={emptyFields.includes('ksMC') ? 'error' : ''}
                    />
                </div>
                
                
            </div>

        </div>
    }

    {showSection3Form &&
        //ΥΠΕΡΓΟΛΑΒΟΙ
        <div className="allSubcontractors">

            <div className="subcontractor">
                <h2>ΥΠΕΡΓΟΛΑΒΟΙ</h2>
            </div>
            <br />
            <div className="subcontractor">
                <h2>ΠΡΟΣΩΠΙΚΟ</h2>
                <span className="material-symbols-outlined">engineering</span>
            </div>
            <div className="Section3">
                <div>
                    <h3>Καλουψιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setKaSub(e.target.value)}
                        value={kaSub}
                        className={emptyFields.includes('kaSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Σιδεράς</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setSiSub(e.target.value)}
                        value={siSub}
                        className={emptyFields.includes('siSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Κτίστης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setKtSub(e.target.value)}
                        value={ktSub}
                        className={emptyFields.includes('ktSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Εργάτης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setErSub(e.target.value)}
                        value={erSub}
                        className={emptyFields.includes('erSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Υδραυλικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setYdSub(e.target.value)}
                        value={ydSub}
                        className={emptyFields.includes('ydSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Ηλεκτρολόγος</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setIlSub(e.target.value)}
                        value={ilSub}
                        className={emptyFields.includes('ilSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Ψυκτικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPsSub(e.target.value)}
                        value={psSub}
                        className={emptyFields.includes('psSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Γυψοσανιδάς</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setGySub(e.target.value)}
                        value={gySub}
                        className={emptyFields.includes('gySub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αλουμινιτζιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setAlSub(e.target.value)}
                        value={alSub}
                        className={emptyFields.includes('alSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Πελεκάνος</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPeSub(e.target.value)}
                        value={peSub}
                        className={emptyFields.includes('peSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μεταλλικός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMetSub(e.target.value)}
                        value={metSub}
                        className={emptyFields.includes('metSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Πογιατζιής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setPoSub(e.target.value)}
                        value={poSub}
                        className={emptyFields.includes('poSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μονωτής</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMoSub(e.target.value)}
                        value={moSub}
                        className={emptyFields.includes('moSub') ? 'error' : ''}
                    />
                </div>
                <div >
                    <h3>Μηχανικός Εργοταξίου</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMeSub(e.target.value)}
                        value={meSub}
                        className={emptyFields.includes('meSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Επιστάτης</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setEpSub(e.target.value)}
                        value={epSub}
                        className={emptyFields.includes('epSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Οδηγός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setOdSub(e.target.value)}
                        value={odSub}
                        className={emptyFields.includes('odSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Μηχανοδηγός</h3>
                    <input
                        type="number"
                        min="0"
                        max="20"
                        onChange={(e) => setMiSub(e.target.value)}
                        value={miSub}
                        className={emptyFields.includes('miSub') ? 'error' : ''}
                    />
                </div>
                

            </div>

            <br />
            <div className="machineries">
                <h2>ΜΗΧΑΝΗΜΑΤΑ</h2>
                <span className="material-symbols-outlined">front_loader</span>
            </div>
            <div className="Section3_M">
                <div>
                    <h3>Εκσκαφέας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setEkSub(e.target.value)}
                        value={ekSub}
                        className={emptyFields.includes('ekSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Φορτηγό</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setFoSub(e.target.value)}
                        value={foSub}
                        className={emptyFields.includes('foSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Οδοστρωτήρας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setOdosSub(e.target.value)}
                        value={odosSub}
                        className={emptyFields.includes('odosSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αντλία</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setAnSub(e.target.value)}
                        value={anSub}
                        className={emptyFields.includes('anSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Βαρέλα</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setVaSub(e.target.value)}
                        value={vaSub}
                        className={emptyFields.includes('vaSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Δονητής</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setDoSub(e.target.value)}
                        value={doSub}
                        className={emptyFields.includes('doSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Αναμικτήρας</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setAmSub(e.target.value)}
                        value={amSub}
                        className={emptyFields.includes('amSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Παλετοφόρο</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setFlSub(e.target.value)}
                        value={flSub}
                        className={emptyFields.includes('flSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Τηλεσκοπικό</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setTiSub(e.target.value)}
                        value={tiSub}
                        className={emptyFields.includes('tiSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Βίντζι</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setViSub(e.target.value)}
                        value={viSub}
                        className={emptyFields.includes('viSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Γερανός</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setGeSub(e.target.value)}
                        value={geSub}
                        className={emptyFields.includes('geSub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Συμπιεστής</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setSySub(e.target.value)}
                        value={sySub}
                        className={emptyFields.includes('sySub') ? 'error' : ''}
                    />
                </div>
                <div>
                    <h3>Κομπρεσσόρος</h3>
                    <input
                        type="number"
                        min="0"
                        max="5"
                        onChange={(e) => setKsSub(e.target.value)}
                        value={ksSub}
                        className={emptyFields.includes('ksSub') ? 'error' : ''}
                    />
                </div>
                
            </div>
        </div>
    }
    {showSection4Form &&
        //ΠΑΡΑΛΑΒΗ & ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        <div className="Section4">
            <div>

                <div className="materials">
                    <h2>ΠΑΡΑΛΑΒΗ</h2>
                    <span className="material-symbols-outlined">local_shipping</span>
                </div>

                <div className={emptyFields.includes('material') ? 'error' : ''}>
                    <textarea
                        className="textArea"
                        maxLength={250}
                        placeholder="Write your message..."
                        onChange={(e) => setMaterial(e.target.value)}
                        value={material}>
                    </textarea>
                </div>

            </div>
            <div>

                <div className="materials">
                    <h2>ΑΠΟΜΑΚΡΥΝΣΗ</h2>
                    <span className="material-symbols-outlined">recycling</span>
                </div>

                <div className={emptyFields.includes('removal') ? 'error' : ''}>
                    <textarea
                        className="textArea"
                        maxLength={250}
                        placeholder="Write your message..."
                        onChange={(e) => setRemoval(e.target.value)}
                        value={removal}>
                    </textarea>
                </div>

            </div>

        </div>
    }
    {showSection5Form &&

        //ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        <div className="inspection">
            <div>
                <div className="delay">
                    <h2>ΚΑΘΥΣΤΕΡΗΣΕΙΣ</h2>
                    <span className="material-symbols-outlined">hourglass_bottom</span>
                </div>

                <div className={emptyFields.includes('delays') ? 'error' : ''}>

                    <textarea
                        className="textArea"
                        maxLength={250}
                        placeholder="Write your message..."
                        onChange={(e) => setDelays(e.target.value)}
                        value={delays}>
                    </textarea>

                </div>
            </div>
        </div>

    }
    {showSection6Form &&

        //ΑΤΥΧΗΜΑΤΑ
        <div className="overtimeWork">
            <div>

                <div className="accidents">
                    <h2>ΑΤΥΧΗΜΑΤΑ</h2>
                    <span className="material-symbols-outlined">personal_injury</span>
                </div>

                <div className={emptyFields.includes('accidents') ? 'error' : ''}>

                    <textarea
                        className="textArea"
                        maxLength={250}
                        placeholder="Write your message..."
                        onChange={(e) => setAccidents(e.target.value)}
                        value={accidents}>
                    </textarea>

                </div>

            </div>
        </div>

    }
    {showSection7Form &&

        //ΕΠΙΣΚΕΠΤΕΣ
        <div className="significantWorks">
            <div>

                <div className="visitors">
                    <h2>ΕΠΙΣΚΕΠΤΕΣ</h2>
                    <span className="material-symbols-outlined">groups</span>
                </div>

                <div className={emptyFields.includes('visitors') ? 'error' : ''}>

                    <textarea
                        className="textArea"
                        maxLength={250}
                        placeholder="Write your message..."
                        onChange={(e) => setVisitors(e.target.value)}
                        value={visitors}>
                    </textarea>

                </div>

            </div>
        </div>

    }
    {showSection8Form &&
        //ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΘ
        <div className="inspection">
            <div>
                <div className="instructions">
                    <h2>ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ</h2>
                    <span className="material-symbols-outlined">folder_special</span>
                </div>
                <div className="Section5">
                    <div className={emptyFields.includes('instructions') ? 'error' : ''}>

                        <textarea
                            className="textArea"
                            maxLength={500}
                            placeholder="Write your message..."
                            onChange={(e) => setInstructions(e.target.value)}
                            value={instructions}>
                        </textarea>

                    </div>
                </div>
            </div>
        </div>
    }

        <div>
           <br />
        </div>

        <div className="date">
            <h2>ΗΜΕΡΟΜΗΝΙΑ</h2>
            <span className="material-symbols-outlined">calendar_month</span>
        </div>

        <div className="Section0">
            <div >
                <DatePicker
                    className={emptyFields.includes('breakTime') ? 'error' : ''}
                    placeholderText={'Επιλέξτε Ημερομηνία'}
                    type="text"
                    selected={selectdate}
                    onChange={handleDateChange}
                    format="dd/mm/yyyy"
                />
            </div>
        </div>

        <div className="Section1">
          <div className="centerSection1_1">
            <div className="time-schedule">
               <h2>ΩΡΕΣ ΕΡΓΑΣΙΑΣ</h2>
               <span className="material-symbols-outlined">schedule</span>
            </div>
            <div className="Section1_1">
                <div>
                    <h3>ΑΠΟ</h3>
                    <select
                        type="text"
                        onChange={handleStartTimeChange}
                        ref={dropdownStartTime}
                        className={emptyFields.includes('startTime') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Ώρα</option>
                        {Object.keys(itemStartTime).map(typeId => (
                            <option
                            options={itemStartTime}
                            value={typeId}
                            >
                                {itemStartTime[typeId]}

                            </option>
                            ))}
                    </select>
                </div>
                <div>
                    <h3>ΕΩΣ</h3>
                    <select
                        type="text"
                        onChange={handleFinishTimeChange}
                        ref={dropdownFinishTime}
                        className={emptyFields.includes('finishTime') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Ώρα</option>
                        {Object.keys(itemFinishTime).map(typeId => (
                            <option
                            options={itemFinishTime}
                            value={typeId}
                            >
                                {itemFinishTime[typeId]}

                            </option>
                            ))
                        }
                    </select>
                </div>
                <div>
                    <h3>ΔΙΑΚΟΠΗ</h3>
                    <select
                        type="text"
                        onChange={handleBreakTimeChange}
                        ref={dropdownBreakTime}
                        className={emptyFields.includes('breakTime') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Λεπτά</option>
                        {Object.keys(itemBreakTime).map(typeId => (
                            <option
                            options={itemBreakTime}
                            value={typeId}
                            >
                                {itemBreakTime[typeId]}

                            </option>
                            ))}
                    </select>
                </div>
            </div>

          </div>

          <br/>

          <div className="centerSection1_2">
            <div className="weather">
              <h2>ΚΑΙΡΙΚΕΣ ΣΥΝΘΗΚΕΣ</h2>
              <span className="material-symbols-outlined">partly_cloudy_day</span>
            </div>
            <div className="Section1_2">
                <div>
                    <h3>ΚΑΙΡΟΣ</h3>

                    <select
                        type="text"
                        onChange={handleWeatherChange}
                        ref={dropdownWeather}
                        className={emptyFields.includes('weather') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Τύπο Καιρού</option>
                        {Object.keys(itemWeatherTypes).map(typeId => (
                            <option
                            options={itemWeatherTypes}
                            value={typeId}
                            >
                                {itemWeatherTypes[typeId]}

                            </option>
                            ))}
                    </select>
                </div>
                <div>
                    <h3>ΑΝΕΜΟΙ</h3>

                    <select
                        type="text"
                        onChange={handleWindChange}
                        ref={dropdownWind}
                        className={emptyFields.includes('wind') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Τύπο Ανέμων</option>
                        {Object.keys(itemWindTypes).map(typeId => (
                            <option
                            options={itemWindTypes}
                            value={typeId}
                            >
                                {itemWindTypes[typeId]}

                            </option>
                            ))}
                    </select>
                </div>

            </div>
          </div>

          <br/>

          <div className="centerSection1_3">
            <div className="temperature">
               <h2>ΘΕΡΜΟΚΡΑΣΙΑ</h2>
               <span className="material-symbols-outlined">device_thermostat</span>
            </div>
            <div className="Section1_3">

                <div>
                    <h3>ΕΛΑΧΙΣΤΗ</h3>

                    <select
                        type="text"
                        onChange={handleTempMinChange}
                        ref={dropdownTempMin}
                        className={emptyFields.includes('tempMin') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Θερμοκρασία</option>
                        {Object.keys(itemTempMin).map(typeId => (
                            <option
                            options={itemTempMin}
                            value={typeId}
                            >
                                {itemTempMin[typeId]}

                            </option>
                            ))}
                    </select>
                </div>
                <div>
                    <h3>ΜΕΓΙΣΤΗ</h3>

                    <select
                        type="text"
                        onChange={handleTempMaxChange}
                        ref={dropdownTempMax}
                        className={emptyFields.includes('tempMax') ? 'error' : ''}
                    >
                        <option value={""}>Επιλέξτε Θερμοκρασία</option>
                        {Object.keys(itemTempMax).map(typeId => (
                            <option
                            options={itemTempMax}
                            value={typeId}
                            >
                                {itemTempMax[typeId]}

                            </option>
                            ))}
                    </select>
                </div>
            </div>
          </div>

        </div>
        <br />
        <div className="centerDescription">
          <div>
            <div className="description">
                <h2>ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ</h2>
                <span class="material-symbols-outlined">task_alt</span>
            </div>
            <div className="Section5">
                <div className={emptyFields.includes('dailyWorks') ? 'error' : ''}>

                    <textarea
                        placeholder="Write your message..."
                        onChange={(e) => setDailyWorks(e.target.value)}
                        value={dailyWorks}>
                    </textarea>

                </div>
            </div>
          </div>
        </div>

        <br />

        <div className="centerSignature">
          <div>
            <div className="signature">
                <h2>ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ</h2>
                <span className="material-symbols-outlined">signature</span>
            </div>
            <div className="canvas">
              <div>
                <div className={emptyFields.includes('signature') ? 'error' : ''}>
                    <SignatureCanvas
                        penColor='blue'
                        backgroundColor='white'
                        canvasProps={{className: "sigCanvas"}}
                        ref={data=>setSign(data)}
                    />
                </div>
                <div className="signatureButtons">
                    <button id="clearSignButton" onClick={handleSignClear}>Clear</button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button id="saveSignButton" onClick={handleSignGenerate}>Save</button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <br />

        <div className="submitDiary">
            <button></button>
       </div>


    </form>
 </div>
)

}

export default DiaryForm
