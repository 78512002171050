import { useDiariesContext } from '../hooks/useDiariesContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { Link } from 'react-router-dom'
import jsPDF from 'jspdf'
import { useState } from 'react'
import { useRef } from "react"

// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { format } from "date-fns"
// for greek characters
import {fontString} from "../font"
import {BACKEND_URI} from "../config"



const DiaryDetails = ({ diary }) => {

    const { dispatch } = useDiariesContext()

    const { user } = useAuthContext()


    // Create PDF file of Daily Diary
    const handlePdfClick = async () => {

        if(!user){
           return
        }

        // Convert HTML variables from DB to Text
        const convertVisitors = diary.visitors.replace(/<[^>]+>/g, '')
        const convertAccidents = diary.accidents.replace(/<[^>]+>/g, '')
        const convertDelays = diary.delays.replace(/<[^>]+>/g, '')
        const convertMaterial = diary.material.replace(/<[^>]+>/g, '')
        const convertRemoval = diary.removal.replace(/<[^>]+>/g, '')
        const convertDailyWorks = diary.dailyWorks.replace(/<[^>]+>/g, '')
        const convertInstructions = diary.instructions.replace(/<[^>]+>/g, '')

        
        // create PDF document
        const doc=new jsPDF('portait', 'px', 'a4', 'false')
        doc.addFileToVFS("Arialbd.ttf", fontString)
        doc.addFont("Arialbd.ttf", "Arialbd", "normal")
        doc.setFont('Arialbd', 'normal')

        
        // Header Title Ημερήσιας Αναφοράς & Αριθμός Αναφοράς
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(25, 15, 'ΕΡΓΟ: ')
        doc.text(50, 15, diary.project_title)
        doc.text(300, 15, 'ΑΡΙΘΜΟΣ ΑΝΑΦΟΡΑΣ: ')
        

        // border of ΗΜΕΡΟΜΗΝΙΑ-ΗΜΕΡΑ-ΩΡΑΡΙΟ
        doc.setLineWidth(1)
        doc.rect(203, 20, 227, 10)

        // border & fill color of ΗΜΕΡΟΜΗΝΙΑ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 20, 58, 20)
        doc.rect(203.5, 20.5, 57, 9, 'F')

        // text of ΗΜΕΡOMHNIA
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 28, 'ΗΜΕΡΟΜΗΝΙΑ')
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(206, 37.5, format(new Date(diary.selectdate), "dd-MM-yyyy"))
        
        
        // border & fill color of ΗΜΕΡΑ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(261, 20, 43, 20)
        doc.rect(261.5, 20.5, 42, 9, 'F')

        // translate name of selected day to greek
        const dayName = format(new Date(diary.selectdate), "EEEE")
        let dayNameGreek = ""
        
        if(dayName==="Monday") {
            dayNameGreek = 'Δευτέρα'
        }
        if(dayName==="Tuesday") {
            dayNameGreek = 'Τρίτη'
        }
        if(dayName==="Wednesday") {
            dayNameGreek = 'Τετάρτη'
        }
        if(dayName==="Thursday") {
            dayNameGreek = 'Πέμπτη'
        }
        if(dayName==="Friday") {
            dayNameGreek = 'Παρασκευή'
        }
        if(dayName==="Saturday") {
            dayNameGreek = 'Σάββατο'
        }
        if(dayName==="Sunday") {
            dayNameGreek = 'Κυριακή'
        }
            
        // text of ΗΜΕΡA
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(264, 28, 'ΗΜΕΡA')
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(264, 37, dayNameGreek )


        // border & fill color of ΩΡΑΡΙΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(304, 20, 126, 20)
        doc.rect(304, 20, 126, 10)
        doc.rect(304.5, 20.5, 125, 9, 'F')

        // text of ΩΡΑΡΙΟ ΕΡΓΑΣΙΑΣ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(307, 28, 'ΩΡΑΡΙΟ ΕΡΓΑΣΙΑΣ')
        doc.setFontSize(9.5)
        doc.rect(392, 20, 38, 20)
        doc.text(394.5, 28, 'ΔΙΑΚΟΠΗ')
        doc.setTextColor(40, 66, 131)
        //ΑΠΟ
        doc.rect(304, 30, 22, 10)
        doc.setFillColor(115, 198, 182)
        doc.rect(304.5, 30.5, 21, 9, 'F')
        doc.setTextColor(0, 0, 0)
        doc.text(307, 37.5,'ΑΠΟ:')
        doc.setTextColor(40, 66, 131)
        doc.text(328, 37.5, diary.startTime)
        //ΕΩΣ
        doc.rect(348, 30, 21, 10)
        doc.setFillColor(115, 198, 182)
        doc.rect(348.5, 30.5, 20, 9, 'F')
        doc.setTextColor(0, 0, 0)
        doc.text(351, 37.5, 'ΕΩΣ:')
        doc.setTextColor(40, 66, 131)
        doc.text(371, 37.5, diary.finishTime)
        //ΔΙΑΚΟΠΗ
        doc.text(405,37.5, diary.breakTime)



        // border of ΚΑΙΡΟΣ-ΑΝΕΜΟΙ-ΘΕΡΜΟΚΡΑΣΙΑ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 40, 227, 20)

        // border of ΚΑΙΡΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 40, 58, 10)
        doc.rect(203.5, 40.5, 57.5, 9, 'F')
        doc.rect(203, 40, 58, 20)

        // text of ΚΑΙΡΙΚΕΣ ΣΥΝΘΗΚΕΣ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 48,'ΚΑΙΡΟΣ')
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(206, 57.5, diary.weather)


        // border of ΑΝΕΜΟΙ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(261, 40, 43, 10)
        doc.rect(261.5, 40.5, 42.5, 9, 'F')
        doc.rect(261, 40, 43, 20)

        // text of ΑΝΕΜΟΙ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(264, 48,'ANEMOI')
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(264, 57.5, diary.wind)


        // border of ΘΕΡΜΟΚΡΑΣΙΑ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(304, 40, 126, 10)
        doc.rect(304.5, 40.5, 125, 9, 'F')

        // border of ΧΑΜΗΛΗ
        doc.rect(304, 50, 37, 10)
        doc.rect(304.5, 50.5, 36, 9, 'F')
        // border of ΥΨΗΛΗ
        doc.rect(369, 50, 32, 10)
        doc.rect(369.5, 50.5, 31, 9, 'F')


        
        // text of ΘΕΡΜΟΚΡΑΣΙΑ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(307,48,'ΘΕΡΜΟΚΡΑΣΙΑ')

        // text of ΧΑΜΗΛΗ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(9)
        doc.text(307, 57.5,'ΧΑΜΗΛΗ:')
        doc.setTextColor(40, 66, 131)
        doc.text(346, 57.5, diary.tempMin)

        // text of ΥΨΗΛΗ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(9)
        doc.text(372, 57.5,'ΥΨΗΛΗ:')
        doc.setTextColor(40, 66, 131)
        doc.text(405, 57.5, diary.tempMax)



        // border of ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 60, 227, 40)
        doc.rect(203, 60, 227, 10)
        doc.rect(203.5, 60.5, 226, 9, 'F')

        //text of ΠΑΡΑΛΑBΗ ΥΛΙΚΩΝ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 68, 'ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ')

        // split text ΠΑΡΑΛΑBΗ ΥΛΙΚΩΝ
        const textlinesMaterial = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertMaterial, 222)
        doc.text(206, 76, textlinesMaterial)


        // border of ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 100, 227, 40)
        doc.rect(203, 100, 227, 10)
        doc.rect(203.5, 100.5, 226, 9, 'F')

        //text of ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 108, 'ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ')

        // split text ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ
        const textlinesRemoval = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertRemoval, 222)
        doc.text(206, 116, textlinesRemoval)



        // border of ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 140, 227, 40)
        doc.rect(203, 140, 227, 10)
        doc.rect(203.5, 140.5, 226, 9, 'F')


        //text of ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 148,'ΚΑΘΥΣΤΕΡΗΣΕΙΣ')

        // split text ΚΑΘΥΣΤΕΡΗΣΕΙΣ
        const textlinesDelays = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertDelays, 222)
        doc.text(206, 156, textlinesDelays)



        // border of ΑΤΥΧΗΜΑΤΑ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 180, 227, 40)
        doc.rect(203, 180, 227, 10)
        doc.rect(203.5, 180.5, 226, 9, 'F')

        //text of ΑΤΥΧΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 188, 'ΑΤΥΧΗΜΑΤΑ')

        // split text ΑΤΥΧΗΜΑΤΑ
        const textlinesAccidents = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertAccidents, 222)
        doc.text(206, 196, textlinesAccidents)

        

        // border of ΕΠΙΣΚΕΠΤΕΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 220, 227, 40)
        doc.rect(203, 220, 227, 10)
        doc.rect(203.5, 220.5, 226, 9, 'F')

        //text of ΕΠΙΣΚΕΠΤΕΣ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 228, 'ΕΠΙΣΚΕΠΤΕΣ')

        // // split text ΕΠΙΣΚΕΠΤΕΣ here we split the text base of text-length and we reduce the font-size of text
        // if(convertVisitors.length > 175){
        const textlinesVisitors = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertVisitors, 222)
        doc.text(206, 236, textlinesVisitors)
        // }else{
        //     const textlinesVisitors = doc.setFont('Arialbd')
        //     .setTextColor(40, 66, 131)
        //     .setFontSize(8)
        //     .splitTextToSize(convertVisitors, 130)
        //     doc.text(23, 552, textlinesVisitors)
        // }



        // border of ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(203, 260, 227, 10)
        doc.rect(203, 260, 227, 80)
        doc.rect(203.5, 260.5, 226, 9, 'F')


        //text of ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(206, 268, 'ΟΔΗΓΙΕΣ ΕΡΓΟΤΑΞΙΟΥ')
        doc.setFontSize(8)
        //doc.text(310, 428, 'ΥΠΟΓΡΑΦΗ ΕΠΙΒΛΕΠΟΝΤΑ ΜΗΧΑΝΙΚΟΥ')
        //doc.addImage(diary.signature,'PNG', 310, 430, 115, 35)

        // split text ΟΔΗΓΙΕΣ/ΠΑΡΑΤΗΡΗΣΕΙΣ ΕΠΙΒΛΕΠΟΝΤΑ
        const textlinesInstructions = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertInstructions, 222)
        doc.text(206, 276, textlinesInstructions)




        // border of ΠΡΟΣΩΠΙΚΟ-ΕΡΓΟΛΑΒΟΥ-ΥΠΕΡΓΟΛΑΒΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 20, 180, 20)

        // border & fill color of ΠΡΟΣΩΠΙΚΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 20, 65, 10)
        doc.rect(20.5, 20.5, 64, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(24, 28, 'ΠΡΟΣΩΠΙΚΟ')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 20, 52, 10)
        doc.rect(85.5, 20.5, 51, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(88, 28, 'ΕΡΓΟΛΑΒΟΥ')

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 20, 63, 10)
        doc.rect(137.5, 20.5, 62, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(140, 28, 'ΥΠΕΡΓΟΛΑΒΩΝ')


        // border & fill color of ΠΡΟΣΩΠΙΚΟ - ΚΑΛΟΥΨΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 30, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 37.5, 'Καλουψιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΑΛΟΥΨΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 30, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 38, diary.kaMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΑΛΟΥΨΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 30, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 38, diary.kaSub)


        // border & fill color of ΠΡΟΣΩΠΙΚΟ - ΣΙΔΕΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 40, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 47.5, 'Σιδεράς')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΣΙΔΕΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 40, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 48, diary.siMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΣΙΔΕΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 40, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 48, diary.siSub)


        // border & fill color of ΚΤΙΣΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 50, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 57.5, 'Κτίστης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΤΙΣΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 50, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 58, diary.ktMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΤΙΣΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 50, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 58, diary.ktSub)


        // border & fill color of EΡΓΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 60, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 67.5, 'Εργάτης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΡΓΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 60, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 68, diary.erMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΡΓΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 60, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 68, diary.erSub)


        // border & fill color of ΥΔΡΑΥΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 70, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 77.5, 'Υδραυλικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΥΔΡΑΥΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 70, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 78, diary.ydMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΥΔΡΑΥΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 70, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 78, diary.ydSub)


        // border & fill color of ΗΛΕΚΤΡΟΛΟΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 80, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 87.5, 'Ηλεκτρολόγος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΗΛΕΚΤΡΟΛΟΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 80, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 88, diary.ilMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΗΛΕΚΤΡΟΛΟΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 80, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 88, diary.ilSub)



        // border & fill color of ΨΥΚΤΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 90, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 97.5, 'Ψυκτικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΨΥΚΤΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 90, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 98, diary.psMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΨΥΚΤΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 90, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 98, diary.psSub)



        // border & fill color of ΓΥΨΟΣΑΝΙΔΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 100, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 107.5, 'Γυψοσανιδάς')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΓΥΨΟΣΑΝΙΔΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 100, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 108, diary.gyMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΓΥΨΟΣΑΝΙΔΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 100, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 108, diary.gySub)



        // border & fill color of ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 110, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 117.5, 'Αλουμινιτζιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 110, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 118, diary.alMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΛΟΥΜΙΝΙΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 110, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 118, diary.alSub)


        // border & fill color of ΠΕΛΕΚΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 120, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 127.5, 'Πελεκάνος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΠΕΛΕΚΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 120, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 128, diary.peMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΠΕΛΕΚΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 120, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 128, diary.peSub)



        // border & fill color of ΜΕΤΑΛΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 130, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 137.5, 'Μεταλλικός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΕΤΑΛΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 130, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 138, diary.metMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΕΤΑΛΛΙΚΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 130, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 138, diary.metSub)


        // border & fill color of ΠΟΓΙΑΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 140, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 147.5, 'Πογιατζιής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΠΟΓΙΑΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 140, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 148, diary.poMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΠΟΓΙΑΤΖΙΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 140, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 148, diary.poSub)



        // border & fill color of ΜΟΝΩΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 150, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 157.5, 'Μονωτής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΟΝΩΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 150, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 158, diary.moMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΟΝΩΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 150, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 158, diary.moSub)



        // border & fill color of ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 160, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 167.5, 'Μηχ. Εργοταξίου')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 160, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 168, diary.meMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΗΧΑΝΙΚΟΣ ΕΡΓΟΤΑΞΙΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 160, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 168, diary.meSub)



        // border & fill color of ΕΠΙΣΤΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 170, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 177.5, 'Επιστάτης')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΠΙΣΤΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 170, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 178, diary.epMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΠΙΣΤΑΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 170, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 178, diary.epSub)



        // border & fill color of ΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 180, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 187.5, 'Oδηγός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 180, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 188, diary.odMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 180, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 188, diary.odSub)



        // border & fill color of ΜΗΧΑΝΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 190, 65, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 197.5, 'Μηχανοδηγός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΜΗΧΑΝΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 190, 52, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 198, diary.miMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΜΗΧΑΝΟΔΗΓΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 190, 63, 10)
        
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 198, diary.miSub)



        // border of ΜΗΧΑΝΗΜΑΤΑ-ΕΡΓΟΛΑΒΟΥ-ΥΠΕΡΓΟΛΑΒΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 200, 180, 20)

        // border & fill color of ΠΡΟΣΩΠΙΚΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 200, 65, 10)
        doc.rect(20.5, 200.5, 64, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(24, 208, 'ΜΗΧΑΝΗΜΑΤΑ')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 200, 52, 10)
        doc.rect(85.5, 200.5, 51, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(88, 208, 'ΕΡΓΟΛΑΒΟΥ')

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 200, 63, 10)
        doc.rect(137.5, 200.5, 62, 9, 'F')
           
        // text of ΠΡΟΣΩΠΙΚΟ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(140, 208, 'ΥΠΕΡΓΟΛΑΒΩΝ')



        // border & fill color of ΕΚΣΚΑΦΕΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 210, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 217.5, 'Εκσκαφέας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΕΚΣΚΑΦΕΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 210, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 218, diary.ekMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΕΚΣΚΑΦΕΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 210, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 218, diary.ekSub)



        // border & fill color of ΦΟΡΤΗΓΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 220, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 227.5, 'Φορτηγό')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΦΟΡΤΗΓΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 220, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 228, diary.foMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΦΟΡΤΗΓΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 220, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 228, diary.foSub)



        // border & fill color of ΟΔΟΣΤΡΩΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 230, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 237.5, 'Οδοστρωτήρας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΟΔΟΣΤΡΩΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 230, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 238, diary.odosMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΟΔΟΣΤΡΩΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 230, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 238, diary.odosSub)



        // border & fill color of ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 240, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 247.5, 'Αντλία Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 240, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 248, diary.anMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΝΤΛΙΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 240, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 248, diary.anSub)



        // border & fill color of ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 250, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 257.5, 'Βαρέλα Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 250, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 258, diary.vaMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΒΑΡΕΛΑ ΣΚΥΡΟΔΕΜΑΤΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 250, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 258, diary.vaSub)



        // border & fill color of ΔΟΝΗΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 260, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 267.5, 'Δονητής Σκυρ/τος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΔΟΝΗΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 260, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 268, diary.doMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΔΟΝΗΤΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 260, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 268, diary.doSub)




        // border & fill color of ΑΝΑΜΙΚΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 270, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 277.5, 'Αναμικτήρας')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΑΝΑΜΙΚΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 270, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 278, diary.amMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΑΝΑΜΙΚΤΗΡΑΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 270, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 278, diary.amSub)



        // border & fill color of FORK LIFT
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 280, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 287.5, 'Παλετοφόρο')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - FORK LIFT
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 280, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 288, diary.flMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - FORK LIFT
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 280, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 288, diary.flSub)



        // border & fill color of ΤΗΛΕΣΚΟΠΙΚΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 290, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 297.5, 'Τηλεσκοπικό')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΤΗΛΕΣΚΟΠΙΚΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 290, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 298, diary.tiMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΤΗΛΕΣΚΟΠΙΚΟ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 290, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 298, diary.tiSub)



        // border & fill color of ΒΙΝΤΖΙ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 300, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 307.5, 'Βίντζι')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΒΙΝΤΖΙ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 300, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 308, diary.viMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΒΙΝΤΖΙ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 300, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 308, diary.viSub)



        // border & fill color of ΓΕΡΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 310, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 317.5, 'Γερανός')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΓΕΡΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 310, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 318, diary.geMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΓΕΡΑΝΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 310, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 318, diary.geSub)



        // border & fill color of ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 320, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 327.5, 'Συμπιεστής')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 320, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 328, diary.syMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΟΠΑΝΟΣ ΣΥΜΠΙΕΣΗΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 320, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 328, diary.sySub)




        // border & fill color of ΚΟΜΠΡΕΣΣΟΡΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 330, 65, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(9)
        doc.setTextColor(40, 66, 131)
        doc.text(24, 337.5, 'Κομπρεσσόρος')

        // border & fill color of ΕΡΓΟΛΑΒΟΥ - ΚΟΜΠΡΕΣΣΟΡΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(85, 330, 52, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(107, 338, diary.ksMC)

        // border & fill color of ΥΠΕΡΓΟΛΑΒΩΝ - ΚΟΜΠΡΕΣΣΟΡΟΣ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(137, 330, 63, 10)
        
        // text of ΜΗΧΑΝΗΜΑΤΑ
        doc.setFontSize(10)
        doc.setTextColor(40, 66, 131)
        doc.text(165, 338, diary.ksSub)



        // border of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.setFillColor(115, 198, 182)
        doc.rect(20, 355, 410, 10)
        doc.rect(20, 355, 410, 225)
        doc.rect(20.5, 355.5, 409, 9, 'F')


        //text of ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(25, 363, 'ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ')


        // split text ΠΡΟΟΔΟΣ ΕΡΓΑΣΙΩΝ
        const textlinesDailyWorks = doc.setFont('Arialbd')
        .setTextColor(40, 66, 131)
        .setFontSize(8)
        .splitTextToSize(convertDailyWorks, 400)
        doc.text(25, 373, textlinesDailyWorks)




        // border of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        doc.setLineWidth(1)
        doc.setDrawColor(0, 0, 0)
        doc.rect(20, 583, 410, 35)

        //text of ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        doc.text(25, 593, 'ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ:')

        doc.addImage(diary.signature,'PNG', 140, 585, 150, 30)

        //save the file
        doc.save(`${format(new Date(diary.selectdate), "dd-MM-yyyy")}_${diary.project_title}.pdf`)
        doc.output('dataurlnewwindow')

    }




    const handleDeleteClick = async () => {
        if(!user){
            return
        }

        const result = window.confirm("Want to Delete this Diary?")

        if (result) {

            const response = await fetch(`${BACKEND_URI}/api/diaries/mydiaries/`+diary._id, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                dispatch({type: 'DELETE_DIARY', payload: json})
            }

        }

    }


    return (

        <div className="diary-details">

            {/* <h4>PROJECT DAILY DIARY</h4> */}

            {/* <h3>ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</h3> */}
            {/* <p><strong>ΤΙΤΛΟΣ ΕΡΓΟΥ: </strong>{diary.project_id}</p> */}
            <div className="diary-content">
                <p><strong>ΗΜΕΡΑ: </strong>{format(new Date(diary.selectdate), "EEEE")}</p>
                <p><strong>ΗΜΕΡΟΜΗΝΙΑ: </strong>{format(new Date(diary.selectdate), "dd-MM-yyyy")}</p>
                <p>{formatDistanceToNow(new Date(diary.createdAt),{ addSuffix: true })}</p>

            </div>
            {/* <p><strong>ΑΡΙΘΜΟΣ ΣΕΛΙΔΑΣ: </strong></p>

            <h3>ΩΡΕΣ ΕΡΓΑΣΙΑΣ</h3>
            <p><strong>ΑΠΟ: </strong>{diary.startTime}</p>
            <p><strong>ΕΩΣ: </strong>{diary.finishTime}</p>
            <p><strong>ΔΙΑΚΟΠΗ: </strong>{diary.breakTime}</p>

            <h3>ΚΑΙΡΙΚΕΣ ΣΥΝΘΗΚΕΣ</h3>
            <p><strong>ΚΑΙΡΟΣ: </strong>{diary.weather}</p>
            <p><strong>ΑΝΕΜΟΙ: </strong>{diary.wind}</p>

            <h3>ΘΕΡΜΟΚΡΑΣΙΑ</h3>
            <p><strong>ΕΛΑΧΙΣΤΗ: </strong>{diary.tempMin}</p>
            <p><strong>ΜΕΓΙΣΤΗ: </strong>{diary.tempMax}</p>

            <h3>ΔΙΑΧΕΙΡΙΣΗ ΥΛΙΚΩΝ</h3>
            <p><strong>ΠΑΡΑΛΑΒΗ ΥΛΙΚΩΝ: </strong></p><p dangerouslySetInnerHTML={{ __html: diary.material }}></p>
            <p><strong>ΑΠΟΜΑΚΡΥΝΣΗ ΥΛΙΚΩΝ: </strong></p><p dangerouslySetInnerHTML={{ __html: diary.removal }}></p>

            <h3>ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ </h3><p dangerouslySetInnerHTML={{ __html: diary.dailyWorks}}></p>

            <h3>ΟΔΗΓΙΕΣ ΕΠΙΒΛΕΠΟΝΤΑ </h3><p dangerouslySetInnerHTML={{ __html: diary.instructions }}></p>

            <h3>ΕΠΙΣΚΕΠΤΕΣ </h3><p dangerouslySetInnerHTML={{ __html: diary.visitors }}></p>
            <h3>ΑΤΥΧΗΜΑΤΑ</h3><p dangerouslySetInnerHTML={{ __html: diary.accidents}}></p>
            <h3>ΚΑΘΥΣΤΕΡΗΣΕΣΙΣ</h3><p dangerouslySetInnerHTML={{ __html: diary.delays }}></p>

            <h3>ΥΠΟΓΡΑΦΗ ΕΡΓΟΛΑΒΟΥ</h3><br/>
            <div className="userSignature">
               <img src={diary.signature} alt="" />
            </div> */}

            <div className="project-buttons">

                <Link className="pdf" to="">
                    <span className="material-symbols-outlined" onClick={handlePdfClick} title="Download Diary Report">file_save</span>
                </Link>
                    &nbsp;
                    &nbsp;
                <span className="material-symbols-outlined" onClick={handleDeleteClick} title="Delete the Diary">delete</span>

            </div>

        </div>

    )
}

export default DiaryDetails
