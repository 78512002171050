import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'


// pages & components
import Home from './pages/Home'
import About from './pages/About'
import Projects from './pages/Projects'
import Staff from './pages/Staff'
import Diaries from './pages/Diaries'
import Book from './pages/Book'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Navbar from './components/Navbar'
import Contact from './pages/Contact'
//import Footer from './components/Footer'
//import ContactInfo from './components/ContactInfo'
// import {browserName, browserVersion, fullBrowserVersion, deviceType, isBrowser} from 'react-device-detect'

const inApp = () => {
    if (navigator.userAgent.includes("Android")) {
        if (navigator.userAgent.includes("wv") || navigator.userAgent.includes("WebView")) {
            return true
        } else {
            return false
        }
    }
    return false
}

function App() {
  const { user, isLoading } = useAuthContext()
  console.log('user:', user)
  console.log('isLoading:', isLoading)
  // null -> loading
  // false -> login
  // user -> myprojects

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <div className="pages">
          {/* <p>browserName={browserName}</p>
          <p>browserVersion={browserVersion}</p>
          <p>fullBrowserVersion={fullBrowserVersion}</p>
          <p>deviceType={deviceType}</p>
          <p>isBrowser={String(isBrowser)}</p>
          <p>inApp={String(inApp())}</p>
          <p>navigator.userAgent={navigator.userAgent}</p> */}
          <Routes>
              <Route
                path="/"
                element={ <Home /> }
              />
              <Route
               path="/about"
               element={ <About /> }
             />
             {(
              isLoading &&
                <Route
                  path="/myprojects"
                  element={ <div/> }
                />
             )}
             {(
              (!isLoading && !user) &&
                <Route
                  path="/myprojects"
                  element={<Navigate to="/login" />}
                />
             )}
             {(
              (!isLoading && user) &&
                <Route
                  path="/myprojects"
                  element={<Projects />}
                />
             )}


             {(
              isLoading &&
                <Route
                  path="/mystaff"
                  element={ <div/> }
                />
             )}
             {(
              (!isLoading && !user) &&
                <Route
                  path="/mystaff"
                  element={<Navigate to="/login" />}
                />
             )}
             {(
              (!isLoading && user) &&
                <Route
                  path="/mystaff"
                  element={<Staff />}
                />
             )}


             {(
              isLoading &&
                <Route
                  path="/myprojects/mydiaries/:projectId"
                  element={ <div/> }
                />
             )}
             {(
              (!isLoading && !user) &&
                <Route
                  path="/myprojects/mydiaries/:projectId"
                  element={<Navigate to="/login" />}
                />
             )}
             {(
              (!isLoading && user) &&
                <Route
                  path="/myprojects/mydiaries/:projectId"
                  element={<Diaries />}
                />
             )}


             {(
              isLoading &&
                <Route
                  path="/myprojects/mydiaries/book/:projectId"
                  element={ <div/> }
                />
             )}
             {(
              (!isLoading && !user) &&
                <Route
                  path="/myprojects/mydiaries/book/:projectId"
                  element={<Navigate to="/login" />}
                />
             )}
             {(
              (!isLoading && user) &&
                <Route
                  path="/myprojects/mydiaries/book/:projectId"
                  element={<Book />}
                />
             )}

             
             <Route
               path="/login"
               element={!user ? <Login /> : <Navigate to="/" /> }
             />
             <Route
               path="/signup"
               element={!user ? <Signup /> : <Navigate to="/" /> }
             />
             <Route
               path="/contact"
               element={ <Contact /> }
             />
          </Routes>
        </div>


      </BrowserRouter>
    </div>
  );
}

export default App;
