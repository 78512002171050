import { useProjectsContext } from '../hooks/useProjectsContext'
import { useAuthContext } from '../hooks/useAuthContext'


// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { Link } from 'react-router-dom'
import {BACKEND_URI} from "../config";


const ProjectDetails = ({ project }) => {

    const redirect = `/myprojects/mydiaries/${project._id}`
    const redirectBook = `/myprojects/mydiaries/book/${project._id}`
    const { dispatch } = useProjectsContext()
    const { user } = useAuthContext()

    const handleClick = async () => {

        if(!user){
            return
        }

        const result = window.confirm("Want to Delete this Project?")

        if (result) {

          const response = await fetch(`${BACKEND_URI}/api/projects/myprojects/${project._id}`, {

              method: 'DELETE',
              headers: {
                  'Authorization': `Bearer ${user.token}`
              }

          })

        const json = await response.json()

        if (response.ok) {

            dispatch({type: 'DELETE_PROJECT', payload: json})

        }

      }


    }


   


    return (

        <div className="project-details">

            <h4>{project.title}</h4>
            <p><strong>ΠΕΡΙΓΡΑΦΗ: </strong>{project.descr}</p>
            <p><strong>ΠΟΛΗ: </strong>{project.town}</p>
            <p><strong>ΔΙΕΥΘΥΝΣΗ: </strong>{project.address}</p>
            <p>{formatDistanceToNow(new Date(project.createdAt),{ addSuffix: true })}</p>

            <div className="project-buttons">

                <Link className="calendar" to={redirect}>

                    <span className="material-symbols-outlined" title="Project Diary">calendar_add_on</span>

                </Link>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                <Link className="staff" to="/mystaff">

                    <span className="material-symbols-outlined" title="Staff List">co_present</span>

                </Link>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                <Link className="book" to={redirectBook}>

                    <span class="material-symbols-outlined" title="Download Project's Book">book_5</span>

                </Link>

                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span className="material-symbols-outlined" onClick={handleClick} title="Delete the Project">delete</span>

            </div>

        </div>

    )
}


export default ProjectDetails
