import {Link} from 'react-router-dom'

const ContactInfo = () => {
  
      return(
        <div className="contact-info-upper-container">
                {/* <div className="contact-info-container">
                    <img
                    src='/assets/phone.png'
                    alt="Phone Number"
                    className="icon contact-icon"/>
                    <p>+357 99863463</p>
                </div> */}
                {/* <div className="contact-info-container">
                    <img
                    src="/assets/linkedin.png"
                    alt="LinkedIn icon"
                    className="icon contact-icon"/>
                    <Link to="https://www.linkedin.com/in/george-valanos-b6156a195/">LinkedIn</Link>
                </div> */}
                <div className="contact-info-container">
                    {/* <img
                    src="/assets/email.png"
                    alt="Email icon"
                    className="icon contact-icon"/> */}
                    <span alt="Email icon" class="material-symbols-outlined">alternate_email</span>
                    <Link to="mailto:mesh-engineers@hotmail.com">mesh-engineers@hotmail.com</Link>
                </div>
            </div>
      )
  }
  
  export default ContactInfo