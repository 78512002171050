import ContactInfo from '../components/ContactInfo'
import Footer from '../components/Footer'
import { Canvas, useFrame } from "@react-three/fiber"
import { OrbitControls, Sphere } from "@react-three/drei"
import { useRef } from 'react'
//import { pointsInner } from "..hooks/utils"
//import { useEffect } from 'react'
//import * as THREE from 'three';
//import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

const About = () => {

    

    return (
        <div className="about">
            <div>
                <p className="section__text__p1">
                    Η συγκεκριμένη εφαρμογή δημιουργήθηκε με σκοπό να αυτοματοποιήσει, και κατ' επέκταση να διευκολύνει, την καθημερινή καταγραφή των πληροφοριών που αφορούν κάθε Εργοτάξιο.
                </p>
                <p className="section__text__p1">
                    Απευθύνεται σε όλες τις εργοληπτικές εταιρείες που φέρουν την ευθύνη της εκτέλεσης και του συντονισμού κάθε έργου, και συγκεκριμένα στους εκάστοτε υπεύθυνους Πολιτικούς Μηχανικούς, Αρχιτέκτονες και Επιστάτες κάθε Εργοταξίου.
                </p>
            </div>
            {/* <img
                    src="/assets/under-construction.jpg"
                    alt="Under construction icon"
                    className="temporary-icon"/> */}

            {/* <canvas id="myThreeJsCanvas"></canvas> */}
            <Canvas
             camera = {{
                position: [0, 0, 50],
             }} 
             style={{height:"100vh"}}>
            
                <OrbitControls maxDistance={15} minDistance={12} />
                <directionalLight />
                <pointLight position={[-30, 0, -30]} power={100.0} />
                <PointCircle />
            </Canvas>    

            <ContactInfo/>

            <Footer/>

        </div>
    )
   
}

const PointCircle = () => {

    const ref = useRef()

    useFrame(({ clock }) => {

        ref.current.rotation.z = clock.getElapsedTime() * 1

    })

    return(

        <group ref={ref}>
            <Sphere position={[-2, -2, -2]} args={[1, 100, 100]}>
                <meshStandardMaterial
                    color="#1aac83"
                    emissive="#1aac83"
                    emissiveIntensity={0.5}
                    roughness={0.5}
                />
            </Sphere>
            <Sphere position={[-2, 2, 2]} args={[1, 100, 100]}>
                <meshStandardMaterial
                    color="#1aac83"
                    emissive="#1aac83"
                    emissiveIntensity={0.5}
                    roughness={0.5}
                />
            </Sphere>
            <Sphere position={[2, 2, 2]} args={[1, 100, 100]}>
                <meshStandardMaterial
                    color="#1aac83"
                    emissive="#1aac83"
                    emissiveIntensity={0.5}
                    roughness={0.5}
                />
            </Sphere>
            <Sphere position={[2, -2, -2]} args={[1, 100, 100]}>
                <meshStandardMaterial
                    color="#1aac83"
                    emissive="#1aac83"
                    emissiveIntensity={0.5}
                    roughness={0.5}
                />
            </Sphere>
            
        </group>
    )
}

export default About