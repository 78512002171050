import React, { useState } from 'react'
import { useProjectsContext } from '../hooks/useProjectsContext'
import { useStaffContext } from '../hooks/useStaffContext'
import { useAuthContext } from '../hooks/useAuthContext'
import { useRef } from "react"
import { Link } from 'react-router-dom'
import {BACKEND_URI} from "../config";

const ProjectForm = () => {

    //Project Form
    const { dispatch } = useProjectsContext()
    const { user } = useAuthContext()
    const [title, setTitle] = useState('')
    const [descr, setDescr] = useState('')
    const [address, setAddress] = useState('')
    const [town, setTown] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    const dropdown = useRef()
    const [showProjectForm, setShowProjectForm] = useState(false)

    //Staff Form
    const { dispatch:dispatchStaff } = useStaffContext()
    const [fullname, setFullname] = useState('')
    const [idnumber, setIdnumber] = useState()
    const [soc_ins, setSoc_ins] = useState()
    const [specialty, setSpecialty] = useState('')
    const dropdownStaff = useRef()
    const [showStaffForm, setShowStaffForm] = useState(false)


    //onClick handle Project Form
    const handleProjectForm = () => {

        setShowProjectForm(!showProjectForm)
        setShowStaffForm(false)

    }

    //onClick handle Staff Form
    const handleStaffForm = () => {

        setShowStaffForm(!showStaffForm)
        setShowProjectForm(false)

    }

    //Project Items for town
    const itemProjectTypes = {

        "0": "Λευκωσία",
        "1": "Λεμεσός",
        "2": "Πάφος",
        "3": "Λάρνακα",
        "4": "Αμμόχωστος"

    }

    //Staff Items for specialty
    const itemStaffTypes = {

        "0": "Εργάτης",
        "1": "Κτίστης",
        "2": "Καλουψιής",
        "3": "Τεχνίτης Κεραμικών",
        "4": "Τεχνίτης Πολυστερίνη/Κόλλα/Δίκτυ",
        "5": "Γυψοσανιδάς",
        "6": "Μπογιατζιής",
        "7": "Υδραυλικός",
        "8": "Ηλεκτρολόγος",
        "9": "Ψυκτικός",
        "10": "Πελεκάνος",
        "11": "Μηχανοδηγός",
        "12": "Οδηγός",
        "13": "Πολιτικός Μηχανικός",
        "14": "Εργοδηγός",

    }

    //dropdown Select Town of Project Form
    const handleProjectChange = (e) => {

        e.preventDefault();
        const itemTypeId = e.target.value
        const itemTypeText  = itemProjectTypes[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Πόλη")){

            setTown(itemTypeText)

        }
    }

    //dropdown Select Specialty of Staff Form
    const handleStaffChange = (e) => {
        e.preventDefault();
        const itemTypeId = e.target.value
        const itemTypeText  = itemStaffTypes[itemTypeId]

        if(!(itemTypeText === "Επιλέξτε Ειδικότητα")){

            setSpecialty(itemTypeText)

        }

    }


    // handle Submit Button of Project Form
    const handleProjectSubmit = async (e) => {
        e.preventDefault()
        if(!user){

            setError('You must be logged in')

            return
        }


        const project = {title, descr, address, town}

        const response = await fetch(`${BACKEND_URI}/api/projects/myprojects/`, {
            method: 'POST',
            body: JSON.stringify(project),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })

        const json = await response.json()

        if (!response.ok){
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setTitle('')
            setDescr('')
            setAddress('')
            setTown('')
            setError(null)
            setEmptyFields([])
            setTimeout(() => {dropdown.current.value = ""}, 500) // <- simulating some process...
            console.log('new project added', json)
            dispatch({type: 'CREATE_PROJECT', payload:json})
        }

    }


    // handle Submit Button of Staff Form
    const handleStaffSubmit = async (e) => {
        e.preventDefault()
        if(!user){

            setError('You must be logged in')

            return
        }

        const staff = {fullname, idnumber, soc_ins, specialty}

        const response = await fetch(`${BACKEND_URI}/api/staff/mystaff/`, {
            method: 'POST',
            body: JSON.stringify(staff),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })

        const json = await response.json()

        if (!response.ok){
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }

        if (response.ok) {
            setFullname('')
            setIdnumber('')
            setSoc_ins('')
            setSpecialty('')
            setError(null)
            setEmptyFields([])
            setTimeout(() => {dropdownStaff.current.value = ""}, 500) // <- simulating some process...
            console.log('new employee added', json)
            dispatchStaff({type: 'CREATE_STAFF', payload:json})

        }

    }



return (
    <div className="projectButtons">

        <form className="createForm">

            <Link to="">

                <button onClick={handleProjectForm}>CREATE PROJECT</button>

            </Link>
            <Link to="/myprojects">

                <button onClick={handleStaffForm}>CREATE STAFF</button>

            </Link>
        </form>

    {showProjectForm &&
        <form className="createProjectForm" onSubmit={handleProjectSubmit}>
            <h2>ΣΤΟΙΧΕΙΑ ΕΡΓΟΥ</h2>

            <label>ΤΙΤΛΟΣ:</label>
            <input
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className={emptyFields.includes('title') ? 'error' : ''}
            />

            <label>ΠΕΡΙΓΡΑΦΗ:</label>
            <input
                type="text"
                onChange={(e) => setDescr(e.target.value)}
                value={descr}
                className={emptyFields.includes('descr') ? 'error' : ''}
            />

            <label>ΠΟΛΗ:</label>
            <select
                type="text"
                onChange={handleProjectChange}
                
                ref={dropdown}
                className={emptyFields.includes('town') ? 'error' : ''}
            >
                <option value={""}>Επιλέξτε Πόλη</option>
                {Object.keys(itemProjectTypes).map(typeId => (
                    <option
                    options={itemProjectTypes}
                    value={typeId}
                    >
                        {itemProjectTypes[typeId]}

                    </option>
                ))}
            </select>

            <label>ΔΙΕΥΘΥΝΣΗ:</label>
            <input
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                className={emptyFields.includes('address') ? 'error' : ''}
            />


            <button>Add Project</button>
            {error && <div className="error">{error}</div>}

        </form>
    }
    {showStaffForm &&

        <form className="createStaffForm" onSubmit={handleStaffSubmit}>
            <h2>ΣΤΟΙΧΕΙΑ ΥΠΑΛΛΗΛΟΥ</h2>

            <label>ΟΝΟΜΑΤΕΠΩΝΥΜΟ:</label>
            <input
                type="text"
                onChange={(e) => setFullname(e.target.value)}
                value={fullname}
                className={emptyFields.includes('fullname') ? 'error' : ''}
            />

            <label>ΕΙΔΙΚΟΤΗΤΑ:</label>
            <select
                type="text"
                onChange={handleStaffChange}
                
                ref={dropdownStaff}
                className={emptyFields.includes('specialty') ? 'error' : ''}
            >
                <option value={""}>Επιλέξτε Ειδικότητα</option>
                {Object.keys(itemStaffTypes).map(typeId => (
                    <option
                    options={itemStaffTypes}
                    value={typeId}
                    >
                        {itemStaffTypes[typeId]}

                    </option>
                ))}
            </select>

            <label>ΑΡ. ΤΑΥΤΟΤΗΤΑΣ:</label>
            <input
                type="number"
                min="0"
                onChange={(e) => setIdnumber(e.target.value)}
                value={idnumber}
                className={emptyFields.includes('idnumber') ? 'error' : ''}
            />

            <label>ΑΡ. ΚΟΙΝ. ΑΣΦΑΛΙΣΕΩΝ:</label>
            <input
                type="number"
                min="0"
                onChange={(e) => setSoc_ins(e.target.value)}
                value={soc_ins}
                className={emptyFields.includes('soc_ins') ? 'error' : ''}
            />

            <button>Add Employee</button>
            {error && <div className="error">{error}</div>}

        </form>
    }

    </div>
)

}

export default ProjectForm
